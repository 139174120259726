import {
  ButtonDS,
  SelectDS,
  AddressFormDS,
  ModalWithLeftPartDS,
  RowElement,
  TextCapitalized,
  Spacer,
  EditOutlined,
  CloseOutlined,
} from "@qivia/ui";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectListAllVehiclesInDb } from "../../../vehicles/vehiclesSlice";
import {
  BadgeAddressInput,
  BadgesListDisplayed,
  ModalVehicleType,
} from "../../badgesAPI";
import { selectBadgesList, createBadgesAsync } from "../../badgesSlice";
import { selectCompany } from "../../../homeSlice";
import { BadgeLogo } from "@qivia/ui/src/assets/assets";
import { selectCompanyAddress } from "../../cardsSlice";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import styled from "styled-components";

const findVehiclesNotAssignedToBadge = (
  vehicles: ModalVehicleType[],
  badgesList: BadgesListDisplayed[],
) => {
  return vehicles.filter(
    (vehicle) =>
      badgesList.find(
        (badge) =>
          (badge.registrationNumber === vehicle.registrationNumber ||
            (vehicle.reference && badge.reference === vehicle.reference) ||
            vehicle.status === "DEACTIVATED") &&
          badge.status !== "IN_OPPOSITION" &&
          badge.status !== "ERROR",
      ) === undefined,
  );
};

export const AddBadgeModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onCloseModal } = props;

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [hasAddressCreated, setHasAddressCreated] = useState<boolean>(false);
  const vehicles = useAppSelector(selectListAllVehiclesInDb);
  const company = useAppSelector(selectCompany);
  const badgesList: BadgesListDisplayed[] = useAppSelector(selectBadgesList);
  const companyAddress = useAppSelector(selectCompanyAddress);

  const addressInitValues = useMemo(() => {
    return {
      name: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      postalCode: "",
      country: "FR",
    };
  }, []);
  const [isValidAddressForm, setIsValidAddressForm] = useState(false);
  const [vehicle, setVehicle] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [addressInput, setAddressInput] =
    useState<BadgeAddressInput>(addressInitValues);

  const vehiclesNotAssignedToBadge: ModalVehicleType[] =
    findVehiclesNotAssignedToBadge(vehicles, badgesList);

  const optionVehicle = useMemo(
    () =>
      vehiclesNotAssignedToBadge.map((vehicle) => {
        return {
          value: vehicle.uuid,
          label: [vehicle.registrationNumber, vehicle.reference]
            .filter((d) => d)
            .join(" - "),
        };
      }),
    [vehiclesNotAssignedToBadge],
  );

  const valueOptionVehicle = useMemo(
    () => optionVehicle.find((option) => option.value === vehicle),
    [optionVehicle, vehicle],
  );

  useEffect(() => {
    if (vehicle && isValidAddressForm) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [isValidAddressForm, vehicle]);

  useEffect(() => {
    hasAddressCreated &&
    (addressInput.addressLine1 === "" ||
      addressInput.city === "" ||
      addressInput.postalCode === "" ||
      addressInput.name === "")
      ? setIsValidAddressForm(false)
      : setIsValidAddressForm(true);
  }, [addressInput, hasAddressCreated]);

  const resetValue = useCallback(() => {
    setAddressInput(addressInitValues);
    setVehicle("");
    setDisplayError(false);
    setHasAddressCreated(false);
  }, [addressInitValues]);

  const onSubmit = useCallback(() => {
    const vehicleData = vehicles.find((v) => v.uuid === vehicle);
    if (!company || !vehicleData) {
      return;
    }
    const badgesData = {
      request: {
        vehicles: [
          {
            uuid: vehicle,
            registrationNumber: vehicleData.registrationNumber,
            reference: vehicleData.reference ?? null,
          },
        ],
        deliveryAddress: hasAddressCreated ? addressInput : null,
      },
      companyUuid: company.uuid,
    };
    void dispatch(createBadgesAsync(badgesData));
    resetValue();
    onCloseModal();
  }, [
    addressInput,
    company,
    dispatch,
    hasAddressCreated,
    onCloseModal,
    resetValue,
    vehicle,
    vehicles,
  ]);

  const updateAddress = useCallback(
    (key: keyof BadgeAddressInput, value: string) => {
      const newValueState = {
        ...addressInput,
        [key]: value,
      };
      setAddressInput(newValueState);
    },
    [addressInput],
  );
  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    if (!buttonDisabled) {
      onSubmit();
    }
  }, [buttonDisabled, onSubmit]);

  if (!company) {
    return;
  }

  return (
    <ModalWithLeftPartDS
      title={t("badges.modal.title") || ""}
      tagText={t("badges.modal.tag") || ""}
      visible={props.visible}
      onClose={() => {
        resetValue();
        props.onCloseModal();
      }}
      leftPart={{
        button: (
          <ButtonDS
            text={t("badges.modal.add") || ""}
            onClick={clickToSubmit}
            disabled={buttonDisabled}
            format="fill"
            buttonType="primary"
          />
        ),
        icon: <BadgeLogo />,
      }}
    >
      <SelectDS
        label={t("badges.modal.vehicle") || ""}
        placeholder={t("badges.modal.vehicle.placeholder") || ""}
        value={valueOptionVehicle}
        options={optionVehicle}
        onChange={(selectedOption) =>
          setVehicle(selectedOption ? selectedOption.value : "")
        }
        error={
          (displayError &&
            vehicle === "" &&
            t("badges.modal.error.selectInput")) ||
          undefined
        }
        allWidth
      />
      {hasAddressCreated ? (
        <StyledFlex>
          <AddressFormDS
            label={t("address.label.delivery") || ""}
            addressForm={addressInput}
            error={displayError}
            onChange={(key, value) => updateAddress(key, value)}
            onKeyDown={(key) => key === "Enter" && clickToSubmit()}
            hasName
          />
          <StyledClose>
            <CloseOutlined onClick={() => setHasAddressCreated(false)} />
          </StyledClose>
        </StyledFlex>
      ) : (
        <>
          <StyledTitle>
            <Spacer x={0.2} />
            <TextCapitalized>{t(`badges.modal.address`)}</TextCapitalized>
          </StyledTitle>
          <Spacer y={1} />
          <RowElement
            text={company.name}
            subText={companyAddress}
            size={"L"}
            rightElement={
              <StyledElement>
                <EditOutlined onClick={() => setHasAddressCreated(true)} />
              </StyledElement>
            }
          />
        </>
      )}
    </ModalWithLeftPartDS>
  );
};

const StyledFlex = styled.div`
  display: flex;
`;

const StyledClose = styled.div`
  cursor: pointer;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  width: 100%;
  ${typographies["Body/M"]};
`;

const StyledElement = styled.div`
  cursor: pointer;
  & svg {
    height: 1.2rem;
  }
`;
