import { useGridApiRef } from "@mui/x-data-grid-premium";
import {
  usePersistMuiTableState,
  MaterialUiColumnsProps,
  LaunchPage,
  MaterialUiTable,
  sortByAlphabeticalOrder,
} from "@qivia/ui";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import { useAppDispatch } from "../../../redux/hooks";
import {
  DriverElectrification,
  DriverElectrificationDisplayed,
} from "./electrificationApi";

const fakeData: DriverElectrification[] = [
  {
    uuid: "569e7062-b666-46be-aca0-d818cde2a258",
    name: "Thibault Rossi",
    prioritizationScore: 2,
    electroCompatibilityScore: 76,
    drivingScore: 82,
    homeChargingScore: 94,
  },
  {
    uuid: "569e7062-b666-46be-aca0-d818cde2a251",
    name: "Jean Dupont",
    prioritizationScore: 1,
    electroCompatibilityScore: 42,
    drivingScore: 72,
    homeChargingScore: 44,
  },
  {
    uuid: "569e7062-b666-46be-aca0-d818cde2a252",
    name: "Marie Jores",
    prioritizationScore: 3,
    electroCompatibilityScore: 88,
    drivingScore: 62,
    homeChargingScore: 54,
  },
  {
    uuid: "569e7062-b666-46be-aca0-d818cde2a253",
    name: "Sophie Ratier",
    prioritizationScore: 4,
    electroCompatibilityScore: 75,
    drivingScore: 62,
    homeChargingScore: 74,
  },
];

export const DriversTab = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const driversListInit: DriverElectrification[] = fakeData;
  //const driversListStatus = useAppSelector(selectDriverElectrificationStatus);

  const [listDisplayed, setListDisplayed] = useState<
    DriverElectrificationDisplayed[] | null
  >(null);

  const pageName = "elecDrivers";
  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      name: "size",
    },
  };
  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const driversList: DriverElectrification[] = useMemo(() => {
    const rowsWithId = driversListInit.map((av) => {
      const newDict: DriverElectrification & { id: string } = {
        ...av,
        id: av.uuid,
      };
      return newDict;
    });
    return sortByAlphabeticalOrder(rowsWithId, "asc", "name");
  }, [driversListInit]);

  // useEffect(() => {
  //   if (company) {
  //     void dispatch(driversListAsync(company.uuid));
  //   }
  // }, [
  //   dispatch,
  //   company,
  // ]);

  //TO ADD LATER
  // useEffect(() => {
  //   if (driversList && driversListStatus === "success") {
  //     const driversDateTimeSorted = sortByDateTime(driversList, "desc");
  //     setListDisplayed(driversDateTimeSorted);
  //   }
  // }, [driversList, driversListStatus]);

  //TO REMOVE LATER
  useEffect(() => {
    if (driversList) {
      const driversSorted = sortByAlphabeticalOrder(driversList, "asc", "name");
      setListDisplayed(driversSorted);
    }
  }, [driversList]);

  const searchBarProps = {
    values: driversList,
    setFilterValues: setListDisplayed,
    keysToIgnore: ["uuid" as const],
  };

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("drivers.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  const columnsDisplayed: {
    [index in keyof DriverElectrificationDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      name: {
        type: "string",
        width: 220,
      },
      prioritizationScore: {
        type: "number",
        width: 200,
      },
      electroCompatibilityScore: {
        type: "number",
        width: 200,
      },
      drivingScore: {
        type: "number",
        width: 200,
      },
      homeChargingScore: {
        type: "number",
        width: 300,
      },
    };
  }, []);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "drivers") {
    return;
  }

  return (
    <MaterialUiTable<
      keyof DriverElectrificationDisplayed,
      DriverElectrificationDisplayed
    >
      rows={listDisplayed}
      columns={columnsDisplayed}
      pageName={pageName}
      dataGridState={dataGridState}
      searchBar={searchBarProps}
      apiRef={apiRef}
      downloadViewAction={downloadViewAction}
    />
  );
};
