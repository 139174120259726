import {
  ButtonDS,
  DialogModalDS,
  SelectDS,
  Spacer,
  SyncOutlined,
  Tag,
  TextCapitalized,
} from "@qivia/ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { PublicTransactionsPropsType } from "../transactionsAPI";
import { CategoryType } from "../../rules/rulesAPI";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectCompany } from "../../homeSlice";
import { createMerchantCodeAsync } from "../transactionsSlice";

const categoriesMerchantCorrection: CategoryType[] = [
  "FUEL_AUTOMATON",
  "SERVICE_STATION",
  "WASH",
  "TOLL",
  "PARKING",
];

export const CategoriesMerchantCorrectionModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
  transaction: PublicTransactionsPropsType;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { visible, onCloseModal, transaction } = props;
  const [category, setCategory] = useState<CategoryType | null>(null);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const company = useAppSelector(selectCompany);

  const resetModal = useCallback(() => {
    setDisplayError(false);
    setCategory(null);
    onCloseModal();
  }, [onCloseModal]);

  const optionsCategories = useMemo(
    () =>
      categoriesMerchantCorrection.map((c) => {
        return {
          value: c,
          label: t(`transactions.category.${c}`),
        };
      }),
    [t],
  );

  const valueCategory = useMemo(
    () => optionsCategories.find((option) => option.value === category),
    [category, optionsCategories],
  );

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    if (!category || !transaction || !company) return;
    void dispatch(
      createMerchantCodeAsync({
        category,
        companyUuid: company.uuid,
        authorizationUuid: transaction.uuid,
      }),
    );
    resetModal();
  }, [category, company, dispatch, resetModal, transaction]);

  return (
    <DialogModalDS
      visible={visible}
      onClose={resetModal}
      title={t("transactions.merchant.authorization.title")}
      width={40}
      bottomButtons={{
        leftButton: (
          <ButtonDS
            text={t("transactions.merchant.authorization.cancel")}
            format={"fill"}
            buttonType={"secondary"}
            onClick={onCloseModal}
          />
        ),
        rightButton: (
          <ButtonDS
            text={t("transactions.merchant.authorization.add")}
            format={"fill"}
            leftIcon={<SyncOutlined />}
            buttonType={"primary"}
            disabled={!category}
            onClick={clickToSubmit}
          />
        ),
      }}
    >
      <StyledColumn>
        <TextCapitalized>
          {t("transactions.merchant.authorization.subTitle")}
        </TextCapitalized>
        <Spacer y={2} />
        <StyledSelectTitle>
          <TextCapitalized>
            {t("transactions.merchant.authorization.selectCategory")}
          </TextCapitalized>
        </StyledSelectTitle>
        <Spacer y={1} />
        <StyledSelect>
          <StyledCategory>
            <Spacer y={1.75} />
            {transaction.merchantName}
            <Spacer y={1} />
            <Tag
              text={t("transactions.merchant.authorization.unknown")}
              textColor={colors["colors/system/informative/informative_normal"]}
              backgroundColor={
                colors["colors/system/informative/informative_veryLight"]
              }
            />
          </StyledCategory>
          <Spacer x={1.25} />
          <StyledIcon>
            <SyncOutlined />
          </StyledIcon>
          <Spacer x={1.25} />
          <StyledCategory>
            <Spacer y={1.75} />
            {transaction.merchantName}
            <Spacer y={0.75} />
            <SelectDS
              label={""}
              options={optionsCategories}
              placeholder={t("transactions.merchant.authorization.category")}
              onChange={(e) => setCategory(e ? e.value : null)}
              value={valueCategory}
              isMandatory
              width={9}
              error={
                displayError && !category
                  ? t("transactions.merchant.authorization.select.error")
                  : undefined
              }
            />
          </StyledCategory>
        </StyledSelect>
        <Spacer y={1.5} />
      </StyledColumn>
    </DialogModalDS>
  );
};

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelectTitle = styled.div`
  display: flex;
  justify-content: center;
  ${typographies["Body/L"]};
`;

const StyledSelect = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCategory = styled.div`
  display: flex;
  flex-direction: column;
  height: 7.5rem;
  width: 100%;
  background-color: ${colors["colors/button/secondary/hover"]};
  border-radius: 0.5rem;
  ${typographies["Body/L"]};
  align-items: center;
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
`;
