import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Assets,
  ButtonDS,
  LockOutlined,
  MessageTooltip,
  NotInterestedOutlined,
  RemoveRedEyeOutlined,
  SidePanelDS,
  Spacer,
  Switch,
  TableTagBadge,
  Tag,
  TextCapitalized,
  triggerToast,
} from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectBadgesList,
  selectBadgeUpdateStatus,
  selectNewBadgeStatus,
  selectReplacementBadgeStatus,
  updateBadgeStatusAsync,
} from "./badgesSlice";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { selectCompany } from "../homeSlice";
import { StepsToInOppositionBadge } from "./modals/badges/StepsToInOppositionModal";

export const BadgeSidePanel: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const params = useParams();
  const updateBadgeStatus = useAppSelector(selectBadgeUpdateStatus);
  const badgesList = useAppSelector(selectBadgesList);
  const company = useAppSelector(selectCompany);
  const badgeNewStatus = useAppSelector(selectNewBadgeStatus);
  const badge = badgesList.find((c) => c.uuid === params.id);
  const [areStepsToInOppositionVisible, setAreStepsToInOppositionVisible] =
    useState(false);
  const replacementBadgeStatus = useAppSelector(selectReplacementBadgeStatus);

  const isBadgeLocked = useMemo(() => {
    return badge?.status === "LOCKED";
  }, [badge?.status]);

  const isBadgeInOpposition = useMemo(() => {
    return badge?.status === "IN_OPPOSITION" || badge?.status === "ERROR";
  }, [badge?.status]);

  const isBadgeToActivate = useMemo(() => {
    return badge?.status === "ORDERED";
  }, [badge?.status]);

  useEffect(() => {
    if (!badge) {
      navigate(`/home/cards/badges`);
    }
  }, [navigate, badge]);

  useEffect(() => {
    if (updateBadgeStatus === "success") {
      triggerToast(
        t(`badges.updatedStatus.${badgeNewStatus}.success`) || "",
        "valid",
      );
    } else if (updateBadgeStatus === "failed") {
      triggerToast(t("badges.updatedStatus.failure") || "", "error");
    }
  }, [updateBadgeStatus, t, badgeNewStatus]);

  useEffect(() => {
    if (replacementBadgeStatus === "success") {
      triggerToast(t(`badges.replacement.success`) || "", "valid");
    } else if (replacementBadgeStatus === "failed") {
      triggerToast(t("badges.replacement.failure") || "", "error");
    }
  }, [replacementBadgeStatus, t]);

  const lockBadge = useCallback(
    (isBadgeLocked: boolean, badgeUuid: string) => {
      if (!company) {
        return;
      }
      void dispatch(
        updateBadgeStatusAsync({
          badgeUuid,
          status: isBadgeLocked ? "LOCKED" : "UNLOCKED",
          companyUuid: company.uuid,
        }),
      );
    },
    [company, dispatch],
  );

  const onSidePanelClosed = useCallback(() => {
    navigate("/home/cards/badges");
  }, [navigate]);

  if (!badge || !company) {
    return;
  }
  return (
    <SidePanelDS.SidePanel
      visible={!!badge}
      onClose={() => !areStepsToInOppositionVisible && onSidePanelClosed()}
      absoluteSection={
        <ButtonDS
          leftIcon={
            <StyledIconColor $isInOpposition={isBadgeInOpposition}>
              <NotInterestedOutlined />
            </StyledIconColor>
          }
          text={t("badges.sidePanel.button.opposition")}
          format={"fill"}
          buttonType={"primary"}
          onClick={() =>
            badge.status !== "IN_OPPOSITION" &&
            setAreStepsToInOppositionVisible(true)
          }
          iconColor={"errorNormal"}
          disabled={isBadgeInOpposition}
        />
      }
    >
      <SidePanelDS.Section
        verticalSpacing={1.5}
        background={colors["colors/button/secondary/hover"]}
      >
        <StyledPanelContainer>
          <Tag
            text={t("badges.sidePanel.title")}
            borderColor={colors["colors/borders/cells/cells"]}
            backgroundColor={colors["colors/text/white"]}
          />
          <Spacer y={1.5} />
          <TableTagBadge status={t(`badges.status.${badge.status}`)} />
          <Spacer y={1} />
          <InformationsBadge
            isBadgeLocked={isBadgeLocked}
            isBadgeToActivate={isBadgeToActivate}
            isBadgeInOpposition={isBadgeInOpposition}
            lockBadge={(isBadgeLocked: boolean, badgeUuid: string) =>
              lockBadge(isBadgeLocked, badgeUuid)
            }
            vehicle={{
              name: badge.registrationNumber,
              uuid: badge.vehicleUuid,
              reference: badge.reference,
            }}
            badgeUuid={badge.uuid}
          />
        </StyledPanelContainer>
      </SidePanelDS.Section>
      <StatementsSummarySectionBadge />
      <StepsToInOppositionBadge
        isVisible={areStepsToInOppositionVisible}
        onCloseModal={() => {
          setAreStepsToInOppositionVisible(false);
        }}
        badge={{ uuid: badge.uuid, number: badge.number }}
        vehicle={{
          registrationNumber: badge.registrationNumber,
          reference: badge.reference,
          uuid: badge.vehicleUuid,
        }}
        companyUuid={company.uuid}
      />
    </SidePanelDS.SidePanel>
  );
};

type Informations = {
  name: string;
  uuid: string;
  reference?: string;
};

const InformationsBadge = (props: {
  isBadgeInOpposition: boolean;
  isBadgeLocked: boolean;
  isBadgeToActivate: boolean;
  lockBadge: (isBadgeLocked: boolean, badgeUuid: string) => void;
  vehicle: Informations;
  badgeUuid: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <SidePanelDS.Section>
      <StyledAssets>
        <Assets.BadgeLogo />
      </StyledAssets>
      <Spacer y={1.5} />
      <SidePanelDS.ActionBlock>
        <SidePanelDS.SectionedActionStripe
          left={
            <StyledRowCenter>
              <LockOutlined />
              <Spacer x={0.25} />
              <TextCapitalized>{t("badges.sidePanel.lock")}</TextCapitalized>
            </StyledRowCenter>
          }
          right={
            <Switch
              onClick={() =>
                props.lockBadge(!props.isBadgeLocked, props.badgeUuid)
              }
              checked={props.isBadgeLocked || false}
              size={"S"}
              disabled={props.isBadgeInOpposition}
            />
          }
        />
      </SidePanelDS.ActionBlock>
      <Spacer y={1} />
      <SidePanelDS.ActionBlock>
        <SidePanelDS.SectionedActionStripe
          left={
            <StyledRowCenter>
              {props.vehicle.name}
              {props.vehicle.reference && (
                <>
                  <Spacer x={1} />
                  <Tag
                    textColor={colors["colors/text/darkGrey"]}
                    text={props.vehicle.reference}
                    backgroundColor={
                      colors["colors/surfaces/background/background_level0"]
                    }
                    borderColor={colors["colors/text/darkGrey"]}
                  />
                </>
              )}
            </StyledRowCenter>
          }
          right={
            <StyledLittleIcon>
              <RemoveRedEyeOutlined
                key="badgeVehicle"
                onClick={() => navigate(`/home/vehicles/${props.vehicle.uuid}`)}
              />
              <Spacer x={1} />
            </StyledLittleIcon>
          }
        />
      </SidePanelDS.ActionBlock>
      {props.isBadgeInOpposition && (
        <>
          <Spacer y={1} />
          <MessageTooltip
            title={t("badges.sidePanel.messageTooltip.opposition.title")}
            text={t("badges.sidePanel.messageTooltip.opposition.text")}
            type="ERROR"
          />
        </>
      )}
      {props.isBadgeToActivate && (
        <>
          <Spacer y={1} />
          <MessageTooltip
            title={t("badges.sidePanel.messageTooltip.toActivate.title")}
            text={t("badges.sidePanel.messageTooltip.toActivate.text")}
            type="INFORMATIVE"
          />
        </>
      )}
    </SidePanelDS.Section>
  );
};

const StatementsSummarySectionBadge = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <SidePanelDS.Section verticalSpacing={2} horizontalSpacing={1.5}>
      {/* TODO LATERs 
      <StyledTitle>
        <TextCapitalized>
          {t("badges.sidePanel.statementsSummary.title")}
        </TextCapitalized>
      </StyledTitle> 
      <Spacer y={2} />*/}
      <ButtonDS
        text={t("badges.sidePanel.button.transactions")}
        format={"hug"}
        buttonType={"secondary"}
        onClick={() => navigate("/home/transactions/badges")}
      />
    </SidePanelDS.Section>
  );
};

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const StyledAssets = styled.div`
  display: flex;
  justify-content: center;
  & svg {
    width: 13rem;
    height: 9.5rem;
  }
`;

// const StyledTitle = styled.div`
//   ${typographies["Body/XL"]}
// `;

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledIconColor = styled.div<{ $isInOpposition: boolean }>`
  & svg {
    fill: ${({ $isInOpposition }) => ($isInOpposition ? "grey" : "")};
  }
`;

const StyledLittleIcon = styled.div`
  display: flex;
  cursor: pointer;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
