import { useEffect, useState } from "react";
import styled from "styled-components";
import { Spacer } from "../Spacer";
import { SideBarIcon } from "./SideBarIcon";
import { SideBarItem } from "./SideBarItem";
import { MenuOptions } from "../Menu/MenuOptions";
import { useTranslation } from "react-i18next";
import {
  AddOutlined,
  LogoutOutlined,
  // SettingsSuggestOutlined,
} from "../../materialUi/materialUi";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { TextCapitalized } from "../../../components/text/TextCapitalized";
import { typographies } from "../../../styles/figmaTypographies";
import { colors } from "../../../styles/figmaColors";
import { ButtonDS } from "../Button";
import { SideBarCompanies } from "./SideBarCompanies";
import { Radio } from "../Radio";
import { SideBarSectionTitle } from "./SideBarSectionTitle";

export interface SideBarProps {
  sections: {
    title: string;
    routes: Array<{
      route: string;
      iconSrc?: JSX.Element;
      title: string;
      newCreation?: boolean;
    }>;
  }[];
  selectedRoute?: string;
  onChangeRoute: (route: string) => void;
  logout: () => void;
  companies: { name: string; uuid: string }[];
  currentCompany: string;
  onChangeCompany: (companyUuid: string) => void;
  onClickCompanyCreation: () => void;
  hasAnalyticsAccess: boolean;
  hasAdvancedAnalyticsAccess: boolean;
  canOrderBadges: boolean;
  isInProduction?: boolean;
}

interface StyledHeaderLogoProps {
  $sideBarSize: SideBarSize;
}

type SideBarSize = "S" | "L";

export const SideBar = (props: SideBarProps) => {
  const { t } = useTranslation();
  const [sideBarSize, setSideBarSize] = useState<SideBarSize>("L");
  const [selectedRoute, setSelectedRoute] = useState<string>(
    props.selectedRoute ?? "",
  );
  const [isMenuOptionsCompaniesOpen, setIsMenuOptionsCompaniesOpen] =
    useState<boolean>(false);

  const onClickArrow = () => {
    sideBarSize === "S" ? setSideBarSize("L") : setSideBarSize("S");
  };

  const onClickLogo = () => {
    setSelectedRoute("");
    props.onChangeRoute("");
  };

  const outsideCompaniesMenuOptions = useOutsideClick(() => {
    setIsMenuOptionsCompaniesOpen(false);
  });

  useEffect(() => {
    if (props.selectedRoute && props.selectedRoute !== selectedRoute) {
      setSelectedRoute(props.selectedRoute);
    }
  }, [props.selectedRoute, selectedRoute]);

  return (
    <StyledContainer $sideBarSize={sideBarSize}>
      <Spacer y={2} />
      <StyledTop>
        <StyledMenu ref={outsideCompaniesMenuOptions}>
          <StyledMenuOptionCompanies>
            {isMenuOptionsCompaniesOpen && (
              <>
                {sideBarSize === "L" ? <Spacer x={2} /> : <Spacer x={11} />}
                <StyledColumn>
                  <MenuOptions
                    options={props.companies.map((company) => {
                      return {
                        action: () => {
                          props.onChangeCompany(company.uuid);
                        },
                        label: (
                          <StyledWordBreak>
                            <StyledJustify>
                              <StyledColumn>
                                <StyledCompaniesTitle>
                                  <TextCapitalized>
                                    {t(`menuOption.entities`)}
                                  </TextCapitalized>
                                </StyledCompaniesTitle>
                                <TextCapitalized>
                                  {company.name}
                                </TextCapitalized>
                              </StyledColumn>
                              <Radio
                                isSelected={
                                  props.currentCompany === company.name
                                }
                              />
                            </StyledJustify>
                            <Spacer x={0.25} />
                          </StyledWordBreak>
                        ),
                        icon: (
                          <SideBarCompanies
                            text={company.name}
                            version={"S"}
                            onClick={() => {
                              props.onChangeCompany(company.uuid);
                            }}
                            hasFocusBorder={true}
                          />
                        ),
                      };
                    })}
                    bottomButton={
                      <ButtonDS
                        format={"fill"}
                        buttonType={"secondary"}
                        text={t(`menuOption.entities.add`)}
                        leftIcon={<AddOutlined />}
                        onClick={props.onClickCompanyCreation}
                      />
                    }
                    width="14.1875"
                  />
                </StyledColumn>
              </>
            )}
          </StyledMenuOptionCompanies>
          <SideBarCompanies
            text={props.currentCompany}
            version={sideBarSize}
            onClick={() =>
              setIsMenuOptionsCompaniesOpen(!isMenuOptionsCompaniesOpen)
            }
            hasFocusBorder={isMenuOptionsCompaniesOpen}
          />
        </StyledMenu>
        <Spacer y={1} />
        <StyledSections>
          {props.sections.map((section) => {
            return (
              <StyledSection key={section.title}>
                <SideBarSectionTitle label={section.title} version="L" />
                {section.routes.map((route, i) => {
                  if (
                    !props.hasAdvancedAnalyticsAccess &&
                    route.route == "fleet"
                  )
                    return;
                  // if (!props.canOrderBadges && route.route == "badges")
                  //   return i === section.routes.length - 1 && <Spacer y={1} />;
                  if (props.isInProduction && route.route == "electrification")
                    return i === section.routes.length - 1 && <Spacer y={1} />;
                  return (
                    <>
                      <SideBarItem
                        label={route.title}
                        icon={route.iconSrc}
                        version={sideBarSize}
                        onClick={() => {
                          setSelectedRoute(route.route);
                          props.onChangeRoute(route.route);
                        }}
                        isSelected={route.route === selectedRoute}
                        isNotification={route.newCreation || false}
                      />
                      {i === section.routes.length - 1 && <Spacer y={1} />}
                    </>
                  );
                })}
              </StyledSection>
            );
          })}
        </StyledSections>
      </StyledTop>
      <StyledBottom $sideBarSize={sideBarSize}>
        {/* <SideBarItem
          label={"menuOption.parameters"}
          icon={<SettingsSuggestOutlined />}
          version={sideBarSize}
          onClick={() => {
            props.onChangeRoute("users");
          }}
          isSelected={false}
          isNotification={false}
        /> */}
        <SideBarItem
          label={"menuOption.logout"}
          icon={<LogoutOutlined />}
          version={sideBarSize}
          onClick={props.logout}
          isSelected={false}
          isNotification={false}
        />
        <Spacer y={0.5} />
        <StyledSideBarIcon>
          <SideBarIcon
            size={sideBarSize}
            onClickArrow={onClickArrow}
            onClickLogo={onClickLogo}
          />
        </StyledSideBarIcon>
      </StyledBottom>
      <Spacer y={2} />
    </StyledContainer>
  );
};

const StyledMenu = styled.div`
  width: 100%;
`;

const StyledMenuOptionCompanies = styled.div`
  z-index: 10;
  position: absolute;
  top: 6rem;
`;

const StyledContainer = styled.div<StyledHeaderLogoProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.5s ease-out;
  padding: 0rem 1rem;
  width: ${({ $sideBarSize }) => ($sideBarSize === "L" ? "17.125rem" : "5rem")};
  min-height: 100%;
`;

const StyledTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const StyledBottom = styled.div<StyledHeaderLogoProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: ${({ $sideBarSize }) =>
    $sideBarSize === "L" ? "11rem" : "15rem"};
  width: 100%;
`;

const StyledSections = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  width: 100%;
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const StyledSideBarIcon = styled.div`
  width: 100%;
`;

const StyledWordBreak = styled.div`
  display: flex;
  word-break: break-all;
`;

const StyledJustify = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCompaniesTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors["colors/text/lightGrey"]};
  ${typographies["Body/XXS"]};
`;
