import { useTranslation } from "react-i18next";
import { HTMLAttributes } from "react";
import styled from "styled-components";
import { typographies } from "../../../styles/figmaTypographies";
import { Spacer } from "../../components/Spacer";
import { TextCapitalized } from "../text/TextCapitalized";

export interface SideBarSectionTitleProps
  extends HTMLAttributes<HTMLDivElement> {
  label: string;
  version: "S" | "L";
}

export const SideBarSectionTitle = ({
  label,
  version,
  ...divProps
}: SideBarSectionTitleProps) => {
  const { t } = useTranslation();

  return (
    <StyledContainer $version={version} {...divProps}>
      <Spacer x={0.75} />
      <Spacer y={1} />
      {version === "L" && (
        <>
          <Spacer x={0.5} />
          <TextCapitalized>
            <StyledText>{t(label)}</StyledText>
          </TextCapitalized>
        </>
      )}
    </StyledContainer>
  );
};

interface StyledContainerProps {
  $version: "S" | "L";
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  align-items: center;
  width: ${({ $version }) => ($version === "S" ? "3rem" : "100%")};
  white-space: nowrap;
  border-radius: 0.25rem;
  height: 1.5rem;
`;

const StyledText = styled.div`
  color: #528892;
  text-transform: uppercase;
  ${typographies["Body/XXXS"]};
`;
