import {
  AddCardOutlined,
  AddOutlined,
  PageDS,
  Spacer,
  TabMenu,
  ToastContainer,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Cards as CardsTab } from "./cards";
import { Badges as BadgesTab } from "./badges";
import { selectCompany } from "../homeSlice";
import { useAppSelector } from "../../../redux/hooks";
import { AddMultipeCardsModal } from "./modals/cards/addMulitpleCardsModal";
import { AddCardModal } from "./modals/cards/addCardModal";
import { AddMultipeBadgesModal } from "./modals/badges/addMulitpleBadgesModal";
import { AddBadgeModal } from "./modals/badges/addBadgeModal";
import { MandateUnsignedModal } from "./modals/badges/mandateUnsignedModal";
import { selectIsStripePlatformMandateSigned } from "../billings/sepaDebitSlice";

export const CardsAndBadges = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const company = useAppSelector(selectCompany);

  const [isCardModalVisible, setIsCardModalVisible] = useState(false);
  const [isMultipleCardsModalVisible, setIsMultipleCardsModalVisible] =
    useState(false);
  const [isCreateBadgeModalVisible, setIsCreateBadgeModalVisible] =
    useState(false);
  const [isModalUnsignedModalVisible, setIsModalUnsignedModalVisible] =
    useState(false);
  const [isMultipleBadgesModalVisible, setIsMultipleBadgesModalVisible] =
    useState(false);
  const [tabs, setTabs] = useState([
    {
      name: "cards",
      title: t("cards.tab.cards"),
    },
  ]);

  const [selectedTab, setSelectedTab] = useState<string>(params.tab ?? "cards");
  const isStripePlatformMandateSigned = useAppSelector(
    selectIsStripePlatformMandateSigned,
  );

  const addCardIsDisabled = useMemo(
    () =>
      company === null ||
      company.iban === "" ||
      company.iban === null ||
      company.bic === "" ||
      company.bic === null,
    [company],
  );

  const hasMandatRequirements = useMemo(() => {
    if (!company) return false;

    const hasQontoMandate =
      company.providerOnboardingStatus === "FINISHED" &&
      company.externalIban &&
      company.qontoMandateSigned;

    const hasMemoBankMandate =
      company.providerOnboardingStatus === "FINISHED" &&
      company.externalIban &&
      company.memoBankMandateSigned;

    const hasStripePlatformMandate =
      company.externalIban &&
      company.canActivatePlatformSepaDebit &&
      (company.platformSepaMandateSigned || isStripePlatformMandateSigned);

    return hasQontoMandate || hasStripePlatformMandate || hasMemoBankMandate;
  }, [company, isStripePlatformMandateSigned]);

  const cta = useMemo(
    () =>
      selectedTab === "cards"
        ? {
            title: t("cards.cta"),
            action: () => {
              if (!addCardIsDisabled) setIsCardModalVisible(true);
            },
            width: 12,
            isDisabled: addCardIsDisabled,
            leftIcon: <AddOutlined />,
          }
        : {
            title: t("badges.cta"),
            action: () => {
              hasMandatRequirements
                ? setIsCreateBadgeModalVisible(true)
                : setIsModalUnsignedModalVisible(true);
            },
            width: 12,
            leftIcon: <AddOutlined />,
          },
    [addCardIsDisabled, hasMandatRequirements, selectedTab, t],
  );

  const cta2 = useMemo(
    () =>
      selectedTab === "cards"
        ? {
            title: t("cards.cta2"),
            action: () => {
              if (!addCardIsDisabled) setIsMultipleCardsModalVisible(true);
            },
            width: 12,
            isDisabled: addCardIsDisabled,
            leftIcon: <AddCardOutlined />,
          }
        : {
            title: t("badges.cta2"),
            action: () => {
              hasMandatRequirements
                ? setIsMultipleBadgesModalVisible(true)
                : setIsModalUnsignedModalVisible(true);
            },
            width: 12,
            leftIcon: <AddOutlined />,
          },
    [addCardIsDisabled, hasMandatRequirements, selectedTab, t],
  );

  useEffect(() => {
    if (selectedTab) {
      navigate(
        `/home/cards/${selectedTab}${
          params.id !== undefined ? `/${params.id}` : ""
        }`,
      );
    }
  }, [navigate, params.id, selectedTab]);

  useEffect(() => {
    if (company && company.canOrderBadges) {
      setTabs((prev) => [
        ...prev,
        {
          name: "badges",
          title: t("cards.tab.badges"),
        },
      ]);
    }
  }, [company, t]);

  if (!company) {
    return;
  }

  if (isMultipleCardsModalVisible)
    return (
      <PageDS
        title={t("cards.multipleCards.title")}
        titleElement={{
          closeButtonAction: () => setIsMultipleCardsModalVisible(false),
        }}
      >
        <AddMultipeCardsModal
          onCloseModal={() => {
            setIsMultipleCardsModalVisible(false);
          }}
        />
      </PageDS>
    );

  if (isMultipleBadgesModalVisible)
    return (
      <PageDS
        title={t("badges.multipleBadges.title")}
        titleElement={{
          closeButtonAction: () => setIsMultipleBadgesModalVisible(false),
        }}
      >
        <AddMultipeBadgesModal
          onCloseModal={() => {
            setIsMultipleBadgesModalVisible(false);
          }}
        />
      </PageDS>
    );

  return (
    <>
      <AddCardModal
        visible={isCardModalVisible}
        onCloseModal={() => {
          setIsCardModalVisible(false);
        }}
      />
      <AddBadgeModal
        visible={isCreateBadgeModalVisible}
        onCloseModal={() => {
          setIsCreateBadgeModalVisible(false);
        }}
      />
      <MandateUnsignedModal
        company={company}
        visible={isModalUnsignedModalVisible}
        onCloseModal={() => {
          setIsModalUnsignedModalVisible(false);
        }}
      />
      <PageDS
        title={t(`cards.tab.${selectedTab}`)}
        cta={cta}
        cta2={cta2}
        toaster={<ToastContainer />}
        topElement={
          <>
            <Spacer y={2} />
            <TabMenu
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <Spacer y={0.5} />
          </>
        }
      >
        <CardsTab />
        <BadgesTab />
      </PageDS>
    </>
  );
};
