import { Trips, TripsDetails } from "../fleet/tripsApi";
import { EcoDrivingScore } from "./driversAPI";

const getTripTotalScore = (
  tripsDetail: TripsDetails[],
  key: keyof TripsDetails,
) => {
  return tripsDetail.reduce(
    (acc, trip) => acc + ((trip[key] as number) ?? 0),
    0,
  );
};

const getDriverTripNumber = (
  trips: Trips[],
  uuid: string,
  key: keyof TripsDetails,
) => {
  return trips
    .filter((trip) => trip.driver?.uuid === uuid)
    .flatMap((trip) => trip.trips.filter((t) => !!t[key])).length;
};

export const totalScoreByDrivers = (trips: Trips[]) => {
  const result = trips
    .filter((trip) => !!trip.driver)
    .reduce(
      (acc, trip) => ({
        ...acc,
        [trip.driver!.uuid]: {
          ecoDrivingScore:
            (acc[trip.driver!.uuid]?.ecoDrivingScore ?? 0) +
            getTripTotalScore(trip.trips, "ecoDrivingScore"),
          smoothnessScore:
            (acc[trip.driver!.uuid]?.smoothnessScore ?? 0) +
            getTripTotalScore(trip.trips, "smoothnessScore"),
          supSpeedScore:
            (acc[trip.driver!.uuid]?.smoothnessScore ?? 0) +
            getTripTotalScore(trip.trips, "supSpeedScore"),
        },
      }),
      {} as Record<string, EcoDrivingScore>,
    );

  Object.keys(result).forEach((res) => {
    result[res] = {
      ecoDrivingScore:
        (result[res]?.ecoDrivingScore ?? 0) /
        getDriverTripNumber(trips, res, "ecoDrivingScore"),
      smoothnessScore:
        (result[res]?.smoothnessScore ?? 0) /
        getDriverTripNumber(trips, res, "smoothnessScore"),
      supSpeedScore:
        (result[res]?.supSpeedScore ?? 0) /
        getDriverTripNumber(trips, res, "supSpeedScore"),
    };
  });

  return result;
};
