import {
  selectVehiclesCreatedStatus,
  selectListAllVehiclesInDb,
  listAllVehiclesInDbAsync,
} from "./../vehicles/vehiclesSlice";
import { driversListAsync, selectDriversList } from "./../drivers/driversSlice";
import {
  cardsListAsync,
  selectCardCreationStatus,
  selectCardsList,
} from "../cardsAndBadges/cardsSlice";
import { selectCompany, selectManager } from "./../homeSlice";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DataCard, Spacer, TextCapitalized } from "@qivia/ui";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  badgesListAsync,
  selectBadgesCreationStatus,
  selectBadgesList,
} from "../cardsAndBadges/badgesSlice";
import { VehiclesListType } from "../vehicles/vehiclesAPI";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { CardsListType } from "../cards/cardsAPI";
import { DriversListType } from "../drivers/driversAPI";
import { BadgesList } from "../cardsAndBadges/badgesAPI";

export const DashboardSummaryCard = (props: {
  count: number;
  label: string;
}) => {
  return (
    <DataCard
      titleHeight=""
      title={
        <>
          <Spacer y={0.5} />
          <StyledTitle>{props.count.toString()}</StyledTitle>
        </>
      }
      content={<TextCapitalized>{props.label}</TextCapitalized>}
      backgroundColor={`${colors["colors/button/secondary/hover"]}`}
      width={15.5}
      borderRadius={0.5}
      withoutBorder
    />
  );
};

export const DashboardTop = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const manager = useAppSelector(selectManager);
  const company = useAppSelector(selectCompany);

  const createdVehicleStatus = useAppSelector(selectVehiclesCreatedStatus);
  const allVehiclesInDb = useAppSelector(selectListAllVehiclesInDb);
  const driversList = useAppSelector(selectDriversList);
  const cardCreationStatus = useAppSelector(selectCardCreationStatus);
  const cardsList = useAppSelector(selectCardsList);
  const badgeCreationStatus = useAppSelector(selectBadgesCreationStatus);
  const badgesList = useAppSelector(selectBadgesList);

  useEffect(() => {
    if (company) {
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
      void dispatch(driversListAsync(company.uuid));
      void dispatch(cardsListAsync(company.uuid));
      void dispatch(badgesListAsync(company.uuid));
    }
  }, [
    dispatch,
    company,
    createdVehicleStatus,
    cardCreationStatus,
    badgeCreationStatus,
  ]);

  const vehiclesActiveList: VehiclesListType[] = useMemo(() => {
    return allVehiclesInDb.filter(
      (vehicle) => vehicle.status === "IN_CIRCULATION",
    );
  }, [allVehiclesInDb]);

  const driversActiveList: DriversListType[] = useMemo(() => {
    return driversList.filter((driver) => driver.status === "ACTIVE");
  }, [driversList]);

  const cardsActiveList: CardsListType[] = useMemo(() => {
    return cardsList.filter((card) => card.status === "ACTIVE");
  }, [cardsList]);

  const badgesActiveList: BadgesList[] = useMemo(() => {
    return badgesList.filter((badge) => badge.status === "ACTIVE");
  }, [badgesList]);

  return (
    <StyledSummary>
      <StyledTitle>
        <TextCapitalized>
          {t("dashboard.summary.title", { firstName: manager?.firstName })}
        </TextCapitalized>
      </StyledTitle>
      <Spacer y={3.5} />
      <StyleFlex>
        <StyledCardsContainer>
          <DashboardSummaryCard
            label={t("dashboard.card.vehicles")}
            count={vehiclesActiveList.length}
          />
          <DashboardSummaryCard
            label={t("dashboard.card.drivers")}
            count={driversActiveList.length}
          />
          <DashboardSummaryCard
            label={t("dashboard.card.cards")}
            count={cardsActiveList.length}
          />
          <DashboardSummaryCard
            label={t("dashboard.card.badges")}
            count={badgesActiveList.length}
          />
        </StyledCardsContainer>
        <Spacer x={1.5} />
      </StyleFlex>
      <Spacer y={2.5} />
    </StyledSummary>
  );
};

const StyledTitle = styled.div`
  display: flex;
  font-size: 2rem;
  font-weight: 500;
`;

const StyledCardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

const StyleFlex = styled.div`
  display: flex;
`;

const StyledSummary = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 70rem;
`;
