import styled from "styled-components";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";
import { FunctionComponent } from "react";
import { Spacer } from "../Spacer";
import { TextCapitalized } from "../text/TextCapitalized";

export type TagOrBadgeType = "TAG" | "BADGE";

// eslint-disable-next-line react-refresh/only-export-components
export const TypeProps = {
  TAG: {
    radius: 0.5,
    spacerx: 0.5,
    spacery: 0.25,
    typo: typographies["Body/XXS"],
  },
  BADGE: {
    radius: 1,
    spacerx: 0.5,
    spacery: 0.25,
    typo: typographies["Body/XXXS"],
  },
};

type TagOrBadgeProps = {
  backgroundColor?: string;
  textColor?: string;
  text?: string | JSX.Element;
  iconLeft?: JSX.Element;
  iconColor?: string;
  borderColor?: string;
  type: TagOrBadgeType;
  colorHover?: string;
  allWidth?: boolean;
  typo?: string;
  tooltip?: JSX.Element;
};

export const TagOrBadge: FunctionComponent<TagOrBadgeProps> = ({
  iconLeft,
  ...props
}) => {
  const options = TypeProps[props.type];

  return (
    <TagOrBadgeContainer
      $backgroundColor={props.backgroundColor}
      $iconColor={props.iconColor}
      $borderColor={props.borderColor}
      $radius={options.radius}
      $colorHover={props.colorHover}
      $allWidth={props.allWidth}
    >
      <Spacer y={options.spacery} />
      <TagOrBadgeMainRow>
        <Spacer x={options.spacerx} />
        {iconLeft}
        {iconLeft && props.text && <Spacer x={0.25} />}
        <TagOrBadgeTextContainer
          $textColor={props.textColor}
          $typo={props.typo ?? options.typo}
        >
          {props.text && <TextCapitalized>{props.text}</TextCapitalized>}
          {props.tooltip && (
            <StyledTooltip>
              <Spacer x={0.25} />
              {props.tooltip}
            </StyledTooltip>
          )}
        </TagOrBadgeTextContainer>
        <Spacer x={options.spacerx} />
      </TagOrBadgeMainRow>
      <Spacer y={options.spacery} />
    </TagOrBadgeContainer>
  );
};

type TagOrBadgeContainerArgs = {
  $backgroundColor?: string;
  $iconColor?: string;
  $borderColor?: string;
  $radius?: number;
  $colorHover?: string;
  $allWidth?: boolean;
};

const TagOrBadgeContainer = styled.div<TagOrBadgeContainerArgs>`
  border-radius: ${({ $radius }) => $radius}rem;
  width: ${({ $allWidth }) => ($allWidth ? "100%" : "fit-content")};
  border: 0.0625rem solid
    ${({ $backgroundColor, $borderColor }) =>
      $borderColor ??
      $backgroundColor ??
      colors["colors/surfaces/background/background_level2"]};
  background-color: ${({ $backgroundColor, $colorHover }) =>
    $colorHover || $backgroundColor};
  ${({ $iconColor }) => $iconColor && `& svg { fill: ${$iconColor} }`}
`;

const TagOrBadgeMainRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

type TagOrBadgeTextContainer = {
  $textColor?: string;
  $typo?: string;
};

const TagOrBadgeTextContainer = styled.span<TagOrBadgeTextContainer>`
  display: inline-flex;
  color: ${({ $textColor }) => $textColor ?? colors["colors/text/black"]};
  text-align: center;
  align-items: center;
  justify-content: center;
  ${({ $typo }) => $typo}
`;

const StyledTooltip = styled.div`
  display: flex;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
