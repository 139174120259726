import React from "react";
import Gauge from "./Charts/Gauge";
import { Spacer } from "./Spacer";
import styled from "styled-components";
import { colors as figmaColors } from "../../styles/figmaColors";
import { colors } from "../../styles/colors";
import { Badge } from "./Tag/Badge";
import { useTranslation } from "react-i18next";
import { TextCapitalized } from "./text/TextCapitalized";

type EcoDrivingScoreDetails = {
  ecoDrivingScore: number | null;
  smoothnessScore: number | null;
  supSpeedScore: number | null;
};

type EcoDrivingScoreDetailsProps = {
  scores: EcoDrivingScoreDetails;
};

const colorsMapping = (value: number, type: keyof EcoDrivingScoreDetails) => {
  switch (type) {
    case "supSpeedScore":
      return value < 75
        ? figmaColors["colors/system/error/error_light"]
        : figmaColors["colors/system/success/success_light"];
    case "smoothnessScore":
      return value < 80
        ? figmaColors["colors/system/error/error_light"]
        : figmaColors["colors/system/success/success_light"];
    default:
      return value < 80
        ? figmaColors["colors/system/error/error_light"]
        : figmaColors["colors/system/success/success_light"];
  }
};

type ScoreRowProps = {
  score: number;
  type: keyof EcoDrivingScoreDetails;
  text: string;
};

const ScoreRow = ({ score, type, text }: ScoreRowProps) => {
  return (
    <StyledFlexJustify>
      <StyledFlexLeft>
        <StyledCircle color={colorsMapping(score, type)} />
        <Spacer x={0.75} />
        <TextCapitalized>{text}</TextCapitalized>
      </StyledFlexLeft>
      <Badge
        text={score.toFixed(0)}
        backgroundColor={colorsMapping(score, type)}
        textColor="#fff"
      />
    </StyledFlexJustify>
  );
};

export default function EcoDrivingScoreDetails(
  props: EcoDrivingScoreDetailsProps,
) {
  const { t } = useTranslation();
  return (
    <div>
      <StyledCenterFlex>
        <Gauge percentage={props.scores.ecoDrivingScore ?? 0} />
      </StyledCenterFlex>
      <Spacer y={2} />
      <ScoreRow
        score={props.scores.supSpeedScore ?? 0}
        text={t("drivers.sidePanel.ecoDrivingScore.supSpeed")}
        type={"supSpeedScore"}
      />
      <Spacer y={0.5} />
      <StyledSeparator />
      <Spacer y={0.5} />
      <ScoreRow
        score={props.scores.smoothnessScore ?? 0}
        text={t("drivers.sidePanel.ecoDrivingScore.consumption")}
        type={"smoothnessScore"}
      />
    </div>
  );
}

const StyledFlexJustify = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledFlexLeft = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCircle = styled.div<{ color: string }>`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 2rem;
  background-color: ${({ color }) => color};
`;

const StyledCenterFlex = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledSeparator = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: solid ${colors.middleGrey} 0.063rem;
`;
