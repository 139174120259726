import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Avatar,
  ButtonDS,
  InputDS,
  LockOutlined,
  NotInterestedOutlined,
  SidePanelDS,
  Spacer,
  Switch,
  Tag,
  TextCapitalized,
} from "@qivia/ui";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import {
  selectDriversList,
  updateDriverAnalyticCode1Async,
  updateDriverAnalyticCode2Async,
  updateDriverStatusAsync,
} from "./driversSlice";
import { useTranslation } from "react-i18next";
import { selectCompany } from "../homeSlice";
import { DriverDeleteModal } from "./driverDeleteModal";
import { DriversListType } from "./driversAPI";
import EcoDrivingScoreDetails from "@qivia/ui/src/designSystem/components/EcoDrivingScoreDetails";

export const DriversSidePanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const driversList = useAppSelector(selectDriversList);
  const company = useAppSelector(selectCompany);
  const driver = driversList.find((d) => d.uuid === params.id);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const updateDriverStatus = useCallback(
    (blockDriver: boolean, driverUuid: string) => {
      if (!company) return;
      void dispatch(
        updateDriverStatusAsync({
          driverUuid,
          status: blockDriver ? "BLOCKED" : "ACTIVE",
          companyUuid: company.uuid,
        }),
      );
    },
    [company, dispatch],
  );

  useEffect(() => {
    if (!driver) {
      navigate(`/home/drivers`);
    }
  }, [navigate, driver]);

  const onSidePanelClosed = useCallback(() => {
    navigate("/home/drivers");
  }, [navigate]);

  const initials = driver && driver.name.split(" ").map((row) => row.charAt(0));
  const isDriverBlocked = useMemo(
    () => driver && driver.status === "BLOCKED",
    [driver],
  );
  const isDriverDelete = useMemo(
    () => driver && driver.status === "DELETE",
    [driver],
  );

  if (!driver || !company) {
    return;
  }

  return (
    <SidePanelDS.SidePanel
      visible={!!driver}
      onClose={onSidePanelClosed}
      absoluteSection={
        <ButtonDS
          leftIcon={
            <StyledIconColor $isDelete={isDriverDelete ?? false}>
              <NotInterestedOutlined />
            </StyledIconColor>
          }
          text={t("drivers.sidePanel.opposition")}
          format={"fill"}
          buttonType={"primary"}
          onClick={() =>
            !isDriverDelete && setIsDeleteModalVisible(!isDeleteModalVisible)
          }
          iconColor={"errorNormal"}
          disabled={isDriverDelete}
        />
      }
    >
      <SidePanelDS.Section
        verticalSpacing={2}
        horizontalSpacing={1.5}
        background={colors["colors/button/secondary/hover"]}
      >
        <StyledPanelContainer>
          {driver.status === "ACTIVE" ? (
            <Tag
              text={t(`drivers.status.${driver.status}`)}
              backgroundColor={
                colors["colors/system/success/success_ultraLight"]
              }
              textColor={colors["colors/system/success/success_normal"]}
            />
          ) : (
            <Tag
              text={t(`drivers.status.${driver.status}`)}
              backgroundColor={
                colors["colors/surfaces/background/background_level2"]
              }
              textColor={colors["colors/text/black"]}
            />
          )}
          <Spacer y={1.5} />
          <Avatar
            size={"XL"}
            color={"mint"}
            firstName={initials ? initials[0] : ""}
            lastName={initials ? initials[initials.length - 1] : ""}
          />
          <Spacer y={1.5} />
          <StyledName>{driver.name}</StyledName>
          <Spacer y={1.5} />
          <SidePanelDS.ActionBlock>
            <SidePanelDS.SectionedActionStripe
              left={
                <StyledRowCenter>
                  <LockOutlined />
                  <Spacer x={0.25} />
                  <TextCapitalized>{t("cards.sidePanel.lock")}</TextCapitalized>
                </StyledRowCenter>
              }
              right={
                <Switch
                  onClick={() =>
                    !isDriverDelete &&
                    updateDriverStatus(!isDriverBlocked, driver.uuid)
                  }
                  checked={isDriverBlocked || false}
                  size={"S"}
                  disabled={isDriverDelete}
                />
              }
            />
          </SidePanelDS.ActionBlock>
        </StyledPanelContainer>
      </SidePanelDS.Section>
      <AnalyticCodesSection
        isDriverDelete={isDriverDelete ?? false}
        driverAnalyticCode1={driver.analyticCode1}
        driverAnalyticCode2={driver.analyticCode2}
        companyUuid={company.uuid}
        driverUuid={driver.uuid}
        driversList={driversList}
      />
      <EcoDrivingScoreSection driver={driver} />
      <DriverDeleteModal
        isVisible={isDeleteModalVisible}
        onCloseModal={() => {
          setIsDeleteModalVisible(false);
        }}
        driverUuid={driver.uuid}
      />
    </SidePanelDS.SidePanel>
  );
};

const AnalyticCodesSection = (props: {
  isDriverDelete: boolean;
  driverAnalyticCode1: string | null;
  driverAnalyticCode2: string | null;
  companyUuid: string;
  driverUuid: string;
  driversList: DriversListType[];
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [analyticCode1, setAnalyticCode1] = useState<string>(
    props.driverAnalyticCode1 ?? "",
  );
  const [analyticCode2, setAnalyticCode2] = useState<string>(
    props.driverAnalyticCode2 ?? "",
  );

  const [displayError, setDisplayError] = useState<boolean>(false);

  const hasDuplicateAnalyticCode1: boolean = useMemo(() => {
    if (analyticCode1 === "") return false;
    if (analyticCode1 === props.driverAnalyticCode1) return false;
    return !!props.driversList.find(
      (driver) => driver.analyticCode1 === analyticCode1,
    );
  }, [analyticCode1, props.driverAnalyticCode1, props.driversList]);

  const hasDuplicateAnalyticCode2: boolean = useMemo(() => {
    if (analyticCode2 === "") return false;
    if (analyticCode2 === props.driverAnalyticCode2) return false;
    return !!props.driversList.find(
      (driver) => driver.analyticCode2 === analyticCode2,
    );
  }, [analyticCode2, props.driverAnalyticCode2, props.driversList]);

  const isValidAnalyticCode1Input: boolean = useMemo(() => {
    return !(props.isDriverDelete || hasDuplicateAnalyticCode1);
  }, [hasDuplicateAnalyticCode1, props.isDriverDelete]);

  const isValidAnalyticCode2Input: boolean = useMemo(() => {
    return !(props.isDriverDelete || hasDuplicateAnalyticCode2);
  }, [hasDuplicateAnalyticCode2, props.isDriverDelete]);

  const onSubmitCode1 = useCallback(() => {
    setDisplayError(true);
    if (isValidAnalyticCode1Input) {
      void dispatch(
        updateDriverAnalyticCode1Async({
          driverUuid: props.driverUuid,
          analyticCode1: analyticCode1,
          companyUuid: props.companyUuid,
        }),
      );
      setDisplayError(false);
    }
  }, [
    dispatch,
    analyticCode1,
    isValidAnalyticCode1Input,
    props.companyUuid,
    props.driverUuid,
  ]);

  const onSubmitCode2 = useCallback(() => {
    setDisplayError(true);
    if (isValidAnalyticCode2Input) {
      void dispatch(
        updateDriverAnalyticCode2Async({
          driverUuid: props.driverUuid,
          analyticCode2: analyticCode2,
          companyUuid: props.companyUuid,
        }),
      );
      setDisplayError(false);
    }
  }, [
    dispatch,
    analyticCode2,
    isValidAnalyticCode2Input,
    props.companyUuid,
    props.driverUuid,
  ]);

  const analyticCode1Error = useMemo(() => {
    return displayError && hasDuplicateAnalyticCode1
      ? t("drivers.sidePanel.analyticCode1.error.alreadyExists")
      : undefined;
  }, [displayError, hasDuplicateAnalyticCode1, t]);

  const analyticCode2Error = useMemo(() => {
    return displayError && hasDuplicateAnalyticCode2
      ? t("drivers.sidePanel.analyticCode2.error.alreadyExists")
      : undefined;
  }, [displayError, hasDuplicateAnalyticCode2, t]);

  return (
    <SidePanelDS.Section verticalSpacing={1.5} horizontalSpacing={1.5}>
      <StyledTitle>
        <TextCapitalized>
          {t("drivers.sidePanel.analyticCode1.title")}
        </TextCapitalized>
      </StyledTitle>
      <Spacer y={1.5} />
      <StyledFlex>
        <InputDS
          placeholder={t("drivers.sidePanel.analyticCode1.input.placeholder")}
          label={t("drivers.sidePanel.analyticCode1.input.title")}
          value={analyticCode1}
          onChange={(e) => setAnalyticCode1(e.target.value)}
          error={analyticCode1Error}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSubmitCode1();
              (e.target as HTMLInputElement).blur();
            }
          }}
          withoutSpacerBottom
          onBlur={() => onSubmitCode1()}
        />
      </StyledFlex>
      <Spacer y={1.5} />
      <StyledFlex>
        <InputDS
          placeholder={t("drivers.sidePanel.analyticCode2.input.placeholder")}
          label={t("drivers.sidePanel.analyticCode2.input.title")}
          value={analyticCode2}
          onChange={(e) => setAnalyticCode2(e.target.value)}
          error={analyticCode2Error}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSubmitCode2();
              (e.target as HTMLInputElement).blur();
            }
          }}
          withoutSpacerBottom
          onBlur={() => onSubmitCode2()}
        />
      </StyledFlex>
    </SidePanelDS.Section>
  );
};

const EcoDrivingScoreSection = (props: { driver: DriversListType }) => {
  const { t } = useTranslation();
  const company = useAppSelector(selectCompany);

  if (!company?.hasAdvancedAnalytics || !props.driver.ecoDrivingScore) return;

  return (
    <SidePanelDS.Section verticalSpacing={1.5} horizontalSpacing={1.5}>
      <StyledTitle>
        <TextCapitalized>
          {t("drivers.sidePanel.ecoDrivingScore.title")}
        </TextCapitalized>
      </StyledTitle>
      <Spacer y={1.5} />
      <EcoDrivingScoreDetails scores={props.driver.ecoDrivingScore} />
    </SidePanelDS.Section>
  );
};

const StyledFlex = styled.div`
  display: flex;
`;

const StyledTitle = styled.div`
  ${typographies["Body/L"]}
  color: ${colors["colors/text/darkGrey"]}
`;

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledName = styled.div`
  ${typographies["Header/H1"]};
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledIconColor = styled.div<{ $isDelete: boolean }>`
  & svg {
    fill: ${({ $isDelete }) => ($isDelete ? "grey" : "")};
  }
`;
