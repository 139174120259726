import { Pie, PieChart, Cell, Label } from "recharts";
import { colors } from "../../../styles/colors";

const Gauge = ({ percentage }: { percentage: number }) => {
  const data = [{ value: percentage }, { value: 100 - percentage }];

  return (
    <PieChart
      height={120}
      width={265}
      margin={{ top: -200, left: 0, right: 0, bottom: 0 }}
    >
      <Pie
        startAngle={180}
        endAngle={0}
        innerRadius="60%"
        data={data}
        dataKey="value"
        labelLine={false}
        blendStroke
        cy={"100%"}
        cornerRadius={"25%"}
        paddingAngle={2}
      >
        <Cell key={1} fill={colors.primary} style={{ outline: "none" }} />
        <Cell key={2} fill={colors.middleGrey} style={{ outline: "none" }} />
        <Label
          value={percentage.toFixed(2)}
          position="centerBottom"
          offset={-20}
          fontSize="30px"
          fontWeight="600"
          fontFamily="Inter"
          fill={colors.grey}
        />
      </Pie>
    </PieChart>
  );
};

export default Gauge;
