import { TextCapitalized, Spacer, ButtonDS } from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch } from "../../../../redux/hooks";
import { createMemobankMandateAsync } from "../sepaDebitSlice";
import { PublicCompany, PublicManager } from "../../homeSlice";
import { useNavigate, useParams } from "react-router-dom";
import { sepaDebitSlice } from "../sepaDebitSlice";

export const MemobankMandateModal = (props: {
  company: PublicCompany;
  manager: PublicManager;
  isVisible: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const clickToSubmit = () => {
    void dispatch(sepaDebitSlice.actions.setMemobankMandateSent(true));
    void dispatch(
      createMemobankMandateAsync({
        companyUuid: props.company.uuid,
      }),
    );
    navigate(`/home/billings/account`);
  };

  useEffect(() => {
    if (!props.isVisible && params.sepa_debit === "mandate_signature_request") {
      navigate(`/home/billings/account`);
    }
  }, [
    navigate,
    params.sepa_debit,
    props.company.canActivatePlatformSepaDebit,
    props.isVisible,
  ]);

  if (!props.isVisible) {
    return;
  }

  return (
    <StyledContainer>
      <StyledModal>
        <Spacer y={2} />
        <StyledConditionsCard>
          <Spacer x={1.5} />
          <StyledDescription>
            <Spacer y={1.5} />
            <TextCapitalized>
              {t("account.memobank.mandate.modal.description")}
            </TextCapitalized>
            <Spacer y={1.5} />
          </StyledDescription>
          <Spacer x={1.5} />
        </StyledConditionsCard>
        <StyledManagerCard>
          <Spacer y={1.5} />
          <StyledRow>
            <Spacer x={1.5} />
            <StyledManagerColumn>
              <TextCapitalized>
                <StyledManagerTitle>
                  {t("account.sepaDebit.modal.manager.firstName")}
                </StyledManagerTitle>
                <Spacer y={0.5} />
                <StyledManagerSubTitle>
                  {props.manager.firstName}
                </StyledManagerSubTitle>
              </TextCapitalized>
            </StyledManagerColumn>
            <StyledManagerColumn>
              <TextCapitalized>
                <StyledManagerTitle>
                  {t("account.sepaDebit.modal.manager.lastName")}
                </StyledManagerTitle>
                <Spacer y={0.5} />
                <StyledManagerSubTitle>
                  {props.manager.lastName}
                </StyledManagerSubTitle>
              </TextCapitalized>
            </StyledManagerColumn>
          </StyledRow>
          <Spacer y={1.5} />
          <StyledRow>
            <Spacer x={1.5} />
            <TextCapitalized>
              <StyledManagerTitle>
                {t("account.sepaDebit.modal.manager.email")}
              </StyledManagerTitle>
              <Spacer y={0.5} />
              <StyledManagerSubTitle>
                {props.manager.email}
              </StyledManagerSubTitle>
            </TextCapitalized>
          </StyledRow>
          <Spacer y={1.5} />
        </StyledManagerCard>

        <StyledCompanyCard>
          <Spacer y={1.5} />
          <StyledRow>
            <Spacer x={1.5} />
            <TextCapitalized>
              <StyledCompanyTitle>
                {t("account.sepaDebit.modal.company.name")}
              </StyledCompanyTitle>
              <Spacer y={0.5} />
              <StyledCompanySubTitle>
                {props.company?.name}
              </StyledCompanySubTitle>
            </TextCapitalized>
            <Spacer x={1.5} />
          </StyledRow>
          <Spacer y={1.5} />
        </StyledCompanyCard>
        <Spacer y={1.5} />

        <ButtonDS
          sizeButton={"S"}
          format={"hug"}
          buttonType={"primary"}
          text={t("account.memobank.mandate.modal.button")}
          onClick={() => clickToSubmit()}
        />
      </StyledModal>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
`;

const StyledModal = styled.div`
  width: 30.5rem;
`;

const StyledRow = styled.div`
  display: flex;
`;

const StyledManagerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const StyledConditionsCard = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors["colors/borders/cells/cells"]};
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  ${typographies["Paragraph/P2"]};
`;

const StyledDescription = styled.div`
  ${typographies["Body/XS"]};
`;

const StyledManagerCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  ${typographies["Paragraph/P2"]};
`;

const StyledManagerTitle = styled.div`
  ${typographies["Body/M"]}
  color: ${colors["colors/text/black"]}
`;

const StyledManagerSubTitle = styled.div`
  ${typographies["Body/S"]}
  color: ${colors["colors/text/black"]}
`;

const StyledCompanyCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  ${typographies["Paragraph/P2"]};
`;

const StyledCompanyTitle = styled.div`
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/black"]}
`;

const StyledCompanySubTitle = styled.div`
  ${typographies["Body/M"]}
  color: ${colors["colors/text/black"]}
`;
