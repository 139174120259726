import {
  ToastContainer,
  triggerToast,
  Spacer,
  LaunchPage,
  sortByDateTime,
  Tag,
  PageDS,
  Avatar,
  MaterialUiTable,
  usePersistMuiTableState,
  MaterialUiColumnsProps,
  renderCellWithLabel,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import {
  driversListAsync,
  selectDriversList,
  selectDriversListStatus,
  driversSlice,
  selectDriverUpdatedStatus,
  selectDriverNewStatus,
  selectDriverAnalyticCode1UpdatedStatus,
  selectDriverAnalyticCode2UpdatedStatus,
} from "./driversSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import {
  GroupsOutlined,
  PersonOutlined,
  NoTransferOutlined,
} from "@qivia/ui/src/designSystem/materialUi/materialUi";
import {
  DriversListDisplayedType,
  DriversListType,
  EcoDrivingScore,
} from "./driversAPI";
import { useNavigate, useParams } from "react-router-dom";
import { DriversSidePanel } from "./SidePanel";
import { DriverAccessRequestSummary } from "./driversAccessRequestSummary";
import { selectCompany } from "../homeSlice";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import {
  formatCarbonEmissionPerKilometer,
  formatCurrency,
  formatLiter,
  formatLiterPerOneHundredMileage,
} from "../../../libSlice/materialUiFormats";
import {
  selectTripsByDayList,
  selectTripsByDayStatus,
  tripsAsync,
} from "../fleet/tripsSlice";
import {
  advancedAnalyticVehiclesAsync,
  selectAdvancedAnalyticsVehiclesList,
  selectAdvancedAnalyticsVehiclesListStatus,
} from "../fleet/fleetSlice";

export const Drivers = () => {
  const { t } = useTranslation();
  const params = useParams<{ id?: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const driversListInit = useAppSelector(selectDriversList);
  const driversListStatus = useAppSelector(selectDriversListStatus);
  const driverUpdatedStatus = useAppSelector(selectDriverUpdatedStatus);
  const driverAnalyticCode1UpdatedStatus = useAppSelector(
    selectDriverAnalyticCode1UpdatedStatus,
  );
  const driverAnalyticCode2UpdatedStatus = useAppSelector(
    selectDriverAnalyticCode2UpdatedStatus,
  );
  const [rowHoverUuid, setRowHoverUuid] = useState<string | null>(null);
  const company = useAppSelector(selectCompany);
  const driverNewStatus = useAppSelector(selectDriverNewStatus);

  const [listDisplayed, setListDisplayed] = useState<
    DriversListDisplayedType[] | null
  >(null);
  const [driver, setDriver] = useState<DriversListType | undefined>();
  const routerParams = useParams();

  const advancedAnalyticsVehicleList = useAppSelector(
    selectAdvancedAnalyticsVehiclesList,
  );
  const advancedAnalyticsVehicleListStatus = useAppSelector(
    selectAdvancedAnalyticsVehiclesListStatus,
  );
  const tripsListInit = useAppSelector(selectTripsByDayList);
  const tripsByDayStatus = useAppSelector(selectTripsByDayStatus);

  const pageName = "drivers";

  const apiRef = useGridApiRef();
  const key = "customers-grid";
  const aggregation = {
    model: {
      name: "size",
      vehicleType: "size",
      status: "size",
      registrationNumber: "size",
      vehicleReference: "size",
      cardLast4Digits: "size",
      averagePricePerLiter: "avg",
      averageQuantity: "avg",
      averageMonthlyAmount: "avg",
      averageConsumption: "avg",
      averageCarbonEmissionPerKm: "avg",
      analyticCode1: "size",
      analyticCode2: "size",
      transactionsWithoutMileageCount: "avg",
      transactionsWithoutSupportingDocumentCount: "avg",
      transactionsCount: "sum",
    },
  };
  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const findKeysToTranslate = useCallback(
    (key: keyof DriversListType, value: string) => {
      switch (key) {
        case "vehicleType":
          return t(`drivers.vehicle.${value}`);
        case "status":
          return t(`drivers.status.${value}`);
        default:
          return value;
      }
    },
    [t],
  );

  const keysToTranslate = useMemo(
    () => ["vehicleType", "status"] as (keyof DriversListDisplayedType)[],
    [],
  );

  const driversList: DriversListType[] = useMemo(() => {
    const rowsWithId = driversListInit.map((av) => {
      let newDict: DriversListType & { id: string } = {
        ...av,
        id: av.uuid,
      };
      Object.entries(av).forEach(([key, value]) => {
        if (keysToTranslate.includes(key as keyof DriversListDisplayedType)) {
          newDict = {
            ...newDict,
            [key]: value
              ? findKeysToTranslate(
                  key as keyof DriversListDisplayedType,
                  value as string,
                )
              : "",
          };
        }
      });
      return newDict;
    });
    return sortByDateTime(rowsWithId, "desc");
  }, [driversListInit, findKeysToTranslate, keysToTranslate]);

  const sidePanelAction = useCallback(
    (props: Partial<DriversListType>) => {
      navigate(`/home/drivers/${props.uuid}`);
    },
    [navigate],
  );
  useEffect(() => {
    if (company?.hasAdvancedAnalytics && driversListStatus === "success")
      dispatch(driversSlice.actions.getEcoDrivingScoreByDriver(tripsListInit));
  }, [
    company?.hasAdvancedAnalytics,
    dispatch,
    driversListStatus,
    tripsListInit,
  ]);

  useEffect(() => {
    if (
      company &&
      company.hasAdvancedAnalytics &&
      advancedAnalyticsVehicleList &&
      advancedAnalyticsVehicleList.length > 0 &&
      advancedAnalyticsVehicleListStatus === "success" &&
      tripsByDayStatus === "idle"
    ) {
      void dispatch(
        tripsAsync({
          companyUuid: company.uuid,
          vehicles: advancedAnalyticsVehicleList,
        }),
      );
    }
  }, [
    dispatch,
    advancedAnalyticsVehicleListStatus,
    advancedAnalyticsVehicleList,
    tripsByDayStatus,
    company,
  ]);

  useEffect(() => {
    void dispatch(driversSlice.actions.resetDriversAccessRequestsListStatus());
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      const driverSelected = driversList.find((d) => d.uuid === params.id);
      if (driverSelected) {
        navigate(`/home/drivers/${driverSelected.uuid}`);
      }
    }
  }, [driversList, navigate, params.id]);

  useEffect(() => {
    if (company) {
      void dispatch(driversListAsync(company.uuid));
      void dispatch(advancedAnalyticVehiclesAsync(company.uuid));
    }
  }, [
    dispatch,
    driverUpdatedStatus,
    driverAnalyticCode1UpdatedStatus,
    driverAnalyticCode2UpdatedStatus,
    company,
    tripsListInit,
  ]);

  useEffect(() => {
    if (driversList && driversListStatus === "success") {
      const driversDateTimeSorted = sortByDateTime(driversList, "desc");
      setListDisplayed(driversDateTimeSorted);
    }
  }, [dispatch, driversList, driversListStatus]);

  useEffect(() => {
    if (!driverNewStatus) return;
    if (driverUpdatedStatus === "success") {
      triggerToast(
        t(`drivers.updatedStatus.${driverNewStatus.toLowerCase()}.success`) ||
          "",
        "valid",
      );
    } else if (driverUpdatedStatus === "failed") {
      triggerToast(t("drivers.updatedStatus.failure") || "", "error");
    }
  }, [driver, driverNewStatus, driverUpdatedStatus, t]);

  useEffect(() => {
    if (driverAnalyticCode1UpdatedStatus === "success") {
      triggerToast(t(`drivers.updatedAnalyticCode1.success`) || "", "valid");
    } else if (driverAnalyticCode1UpdatedStatus === "failed") {
      triggerToast(t("drivers.updatedAnalyticCode1.failure") || "", "error");
    }
  }, [driver, driverNewStatus, driverAnalyticCode1UpdatedStatus, t]);

  useEffect(() => {
    if (driverAnalyticCode2UpdatedStatus === "success") {
      triggerToast(t(`drivers.updatedAnalyticCode2.success`) || "", "valid");
    } else if (driverAnalyticCode2UpdatedStatus === "failed") {
      triggerToast(t("drivers.updatedAnalyticCode2.failure") || "", "error");
    }
  }, [driver, driverNewStatus, driverAnalyticCode2UpdatedStatus, t]);

  useEffect(() => {
    if (listDisplayed) {
      const driverFound = driversList.find(
        (driver) => driver.uuid === routerParams.id,
      );
      setDriver(driverFound);
      if (!driverFound && routerParams.id) {
        triggerToast(t("drivers.notFound.error") || "", "error");
        return;
      }
    }
  }, [driversList, listDisplayed, routerParams, t]);

  const searchBarProps = {
    values: driversList,
    setFilterValues: setListDisplayed,
    keysToIgnore: ["uuid" as const, "date" as const],
  };

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("drivers.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  const columnsDisplayed: {
    [index in keyof DriversListDisplayedType]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      name: {
        type: "string",
        width: 250,
        valueFormatter: (value: string, row) => {
          const initials = value?.split(" ").map((row) => row.charAt(0));
          return (
            value && (
              <StyledRow>
                <Avatar
                  color={"mint"}
                  size={"S"}
                  firstName={initials[0]}
                  lastName={initials[initials.length - 1]}
                  isHover={rowHoverUuid === row["uuid"]}
                />
                <Spacer x={0.75} />
                {value}
              </StyledRow>
            )
          );
        },
      },
      vehicleType: {
        type: "string",
        width: 200,
        valueFormatter: (value: string, row) =>
          value && (
            <StyledRow>
              <Tag
                text={value}
                backgroundColor={colors["colors/button/secondary/hover"]}
                iconLeft={
                  value === "PERSONAL" ? (
                    <PersonOutlined />
                  ) : value === "OUT_OF_FLEET" ? (
                    <NoTransferOutlined />
                  ) : (
                    <GroupsOutlined />
                  )
                }
                colorHover={
                  rowHoverUuid === row["uuid"]
                    ? colors["colors/surfaces/background/background_level0"]
                    : undefined
                }
              />
            </StyledRow>
          ),
      },
      status: {
        type: "string",
        valueFormatter: (value: string, row) =>
          value && (
            <StyledRow>
              <Tag
                text={value}
                backgroundColor={
                  value === t("drivers.status.ACTIVE")
                    ? colors["colors/system/success/success_ultraLight"]
                    : colors["colors/surfaces/background/background_level2"]
                }
                textColor={
                  value === t("drivers.status.ACTIVE")
                    ? colors["colors/system/success/success_normal"]
                    : colors["colors/text/black"]
                }
                colorHover={
                  value !== t("drivers.status.ACTIVE") &&
                  rowHoverUuid === row["uuid"]
                    ? colors["colors/surfaces/background/background_level0"]
                    : undefined
                }
              />
            </StyledRow>
          ),
      },
      cardLast4Digits: {
        type: "string",
        valueFormatter: (value: string) => value && `** ${value}`,
      },
      registrationNumber: {
        type: "string",
      },
      vehicleReference: {
        type: "string",
        width: 250,
        valueFormatter: (value) =>
          value && (
            <StyledRow>
              <div style={{ width: "2rem" }}>
                <img
                  src={Car}
                  style={{ width: "100%", filter: "grayscale(100%)" }}
                  alt="Car"
                />
              </div>
              <Spacer x={0.5} />
              <Tag
                textColor={colors["colors/text/black"]}
                backgroundColor={
                  colors["colors/surfaces/background/background_level0"]
                }
                text={value}
                borderColor={colors["colors/borders/cells/cells"]}
              />
            </StyledRow>
          ),
      },
      averageMonthlyAmount: {
        type: "number",
        width: 250,
        valueFormatter: formatCurrency,
      },
      averagePricePerLiter: {
        type: "number",
        valueFormatter: formatCurrency,
      },
      averageQuantity: {
        type: "number",
        valueFormatter: formatLiter,
      },
      averageConsumption: {
        type: "number",
        width: 200,
        valueFormatter: formatLiterPerOneHundredMileage,
      },
      averageCarbonEmissionPerKm: {
        type: "number",
        width: 250,
        valueFormatter: formatCarbonEmissionPerKilometer,
      },
      analyticCode1: {
        type: "string",
        width: 200,
      },
      analyticCode2: {
        type: "string",
        width: 200,
      },
      transactionsWithoutMileageCount: {
        type: "number",
        width: 200,
      },
      transactionsWithoutSupportingDocumentCount: {
        type: "number",
        width: 200,
      },
      transactionsCount: {
        type: "number",
        width: 200,
        renderCell: (
          p: GridRenderCellParams<
            GridValidRowModel,
            unknown,
            unknown,
            GridTreeNodeWithRender
          >,
        ) => renderCellWithLabel(p, "Somme"),
      },
      ...(company?.hasAdvancedAnalytics
        ? {
            ecoDrivingScore: {
              type: "number",
              width: 150,
              valueGetter: (val: EcoDrivingScore) => {
                return val?.ecoDrivingScore ?? "";
              },
            },
          }
        : {}),
    };
  }, [company?.hasAdvancedAnalytics, rowHoverUuid, t]);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  return (
    <PageDS
      title={t("drivers.title")}
      //isEmptyTable={listDisplayed.length === 0}
      toaster={<ToastContainer />}
      topElement={<DriverAccessRequestSummary />}
    >
      <MaterialUiTable<keyof DriversListDisplayedType, DriversListDisplayedType>
        rows={listDisplayed}
        columns={columnsDisplayed}
        pageName={pageName}
        onRowClickAction={sidePanelAction}
        dataGridState={dataGridState}
        searchBar={searchBarProps}
        apiRef={apiRef}
        downloadViewAction={downloadViewAction}
        setRowHoverUuid={setRowHoverUuid}
      />
      <DriversSidePanel />
    </PageDS>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
