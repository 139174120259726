import { FormEvent } from "react";
import { InputDS, InputDSProps } from "./Input";

interface NumericInputProps extends InputDSProps {
  label?: string | JSX.Element | JSX.Element[];
  value: number | "";
  update: (e: number | "") => void;
  floatNumber?: boolean;
  maxDigits?: number; //10 by default
  withoutSpacerBottom?: boolean;
  error?: string;
}

export const NumericInputDS = (props: NumericInputProps) => {
  const updateValue = (e: FormEvent<HTMLInputElement>) => {
    const regexNumber = props.maxDigits
      ? new RegExp("^([0-9]){0," + props.maxDigits.toString() + "}$")
      : /^([0-9]){0,10}$/;
    const regexFloatNumber = props.maxDigits
      ? new RegExp(
          "^([0-9]{0," + props.maxDigits.toString() + "})([.][0-9]{0,3})?$",
        )
      : /^([0-9]{0,10})([.][0-9]{0,3})?$/;

    const number = (e.target as HTMLInputElement).value;

    if (!number || number === "") {
      return props.update("");
    }
    if (!props.floatNumber && number.match(regexNumber)) {
      return props.update(parseInt(number));
    }
    if (props.floatNumber && number.match(regexFloatNumber)) {
      return props.update(parseFloat(number));
    }
    return props.update(props.value);
  };
  return (
    <InputDS
      label={props.label}
      placeholder={props.placeholder}
      error={props.error}
      onChange={updateValue}
      pattern="[0-9]*"
      type={"NUMBER"}
      value={props.value}
      withoutSpacerBottom={props.withoutSpacerBottom}
      onKeyDown={(e) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
      }
    />
  );
};
