import { createSlice } from "@reduxjs/toolkit";

export interface ElectrificationState {}

const initialState: ElectrificationState = {};

export const electrificationSlice = createSlice({
  name: "electrification",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder;
  },
});

export default electrificationSlice.reducer;
