import { LineChartDS } from "@qivia/ui/src/designSystem/components/Charts/LineChart";
import { useTranslation } from "react-i18next";
import { EmptyChart } from "./emptyChart";
import { useSelector } from "react-redux";
import { selectBalancePerPeriod } from "../../homeSlice";
import { TotalType } from "@qivia/ui/src/designSystem/components/Charts/CardChart";
import {
  dateFormatterDayShortMonthYear,
  dateFormatterMonthYear,
} from "@qivia/ui";

export const BalanceChart = (props: { totalType: TotalType }) => {
  const { t } = useTranslation();
  const balancePerPeriod = useSelector(selectBalancePerPeriod);
  const dateFormatter =
    props.totalType === "month"
      ? dateFormatterDayShortMonthYear
      : dateFormatterMonthYear;

  const balanceData = balancePerPeriod
    ? balancePerPeriod[props.totalType].map((bpp) => ({
        value: bpp.amount / 100,
        date: dateFormatter(new Date(bpp.day)),
      }))
    : [];

  if (balanceData.length < 1) return;

  return (
    <>
      {!balanceData.length ? (
        <EmptyChart />
      ) : (
        <LineChartDS
          transactions={balanceData}
          legend={t("dashboard.charts.title.balancePerPeriod.legend")}
          unit={t("dashboard.charts.title.balancePerPeriod.unit")}
        />
      )}
    </>
  );
};
