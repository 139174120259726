import { useGridApiRef } from "@mui/x-data-grid-premium";
import {
  usePersistMuiTableState,
  MaterialUiColumnsProps,
  LaunchPage,
  MaterialUiTable,
  sortByDateTime,
} from "@qivia/ui";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { documentDownloadXlsxAsync } from "../../../libSlice/downloadFileXslx";
import { useAppDispatch } from "../../../redux/hooks";
import {
  VehicleElectrification,
  VehicleElectrificationDisplayed,
} from "./electrificationApi";
import { formatDate, getDate } from "../../../libSlice/materialUiFormats";

const fakeData: VehicleElectrification[] = [
  {
    uuid: "569e7062-b666-46be-aca0-d818cde2a258",
    reference: "#12345",
    registrationNumber: "AA-876-PM",
    model: "Renault Clio",
    startDate: new Date("2024-02-02").toISOString(),
    endDate: new Date("2025-03-02").toISOString(),
    prioritizationScore: 2,
    optimalBatteryCapacity: 27.4,
    tripsScore: 82,
    distanceScore: 94,
    driverElectroCompatibilityScore: 95,
  },
  {
    uuid: "569e7062-b666-46be-aca0-d818cde2a251",
    reference: "ref_2",
    registrationNumber: "BB-876-PM",
    model: "Renault Captur",
    startDate: new Date("2024-02-02").toISOString(),
    endDate: new Date("2025-03-02").toISOString(),
    prioritizationScore: 1,
    optimalBatteryCapacity: 34,
    tripsScore: 72,
    distanceScore: 44,
    driverElectroCompatibilityScore: 75,
  },
  {
    uuid: "569e7062-b666-46be-aca0-d818cde2a252",
    reference: "clio_4",
    registrationNumber: "CC-876-PM",
    model: "Renault Clio",
    startDate: new Date("2024-02-02").toISOString(),
    endDate: new Date("2025-03-02").toISOString(),
    prioritizationScore: 3,
    optimalBatteryCapacity: 27.4,
    tripsScore: 62,
    distanceScore: 54,
    driverElectroCompatibilityScore: 65,
  },
  {
    uuid: "569e7062-b666-46be-aca0-d818cde2a253",
    reference: null,
    registrationNumber: "DD-876-PM",
    model: "Renault Clio",
    startDate: new Date("2025-02-02").toISOString(),
    endDate: new Date("2025-03-02").toISOString(),
    prioritizationScore: 4,
    optimalBatteryCapacity: 24,
    tripsScore: 62,
    distanceScore: 74,
    driverElectroCompatibilityScore: 75,
  },
];

export const VehiclesTab = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const vehiclesListInit: VehicleElectrification[] = fakeData;
  //const vehiclesListStatus = useAppSelector(selectVehicleElectrificationStatus);

  const [listDisplayed, setListDisplayed] = useState<
    VehicleElectrificationDisplayed[] | null
  >(null);

  const pageName = "elecVehicles";
  const apiRef = useGridApiRef();
  const key = "customers-grid";

  const aggregation = {
    model: {
      reference: "size",
      registrationNumber: "size",
      model: "size",
      startDate: "size",
      endDate: "size",
    },
  };
  const dataGridState = usePersistMuiTableState(
    apiRef,
    key,
    pageName,
    aggregation,
  );

  const vehiclesList: VehicleElectrification[] = useMemo(() => {
    const rowsWithId = vehiclesListInit.map((av) => {
      const newDict: VehicleElectrification & { id: string } = {
        ...av,
        id: av.uuid,
      };
      return newDict;
    });
    return sortByDateTime(rowsWithId, "desc", "startDate");
  }, [vehiclesListInit]);

  // useEffect(() => {
  //   if (company) {
  //     void dispatch(vehiclesListAsync(company.uuid));
  //   }
  // }, [
  //   dispatch,
  //   company,
  // ]);

  //TO ADD LATER
  // useEffect(() => {
  //   if (vehiclesList && vehiclesListStatus === "success") {
  //     const vehiclesDateTimeSorted = sortByDateTime(vehiclesList, "desc");
  //     setListDisplayed(vehiclesDateTimeSorted);
  //   }
  // }, [vehiclesList, vehiclesListStatus]);

  //TO REMOVE LATER
  useEffect(() => {
    if (vehiclesList) {
      const vehiclesSorted = sortByDateTime(vehiclesList, "desc", "startDate");
      setListDisplayed(vehiclesSorted);
    }
  }, [vehiclesList]);

  const searchBarProps = {
    values: vehiclesList,
    setFilterValues: setListDisplayed,
    keysToIgnore: ["uuid" as const],
  };

  const downloadViewAction = useCallback(
    (file: Buffer | undefined) => {
      if (!file) return;
      void dispatch(
        documentDownloadXlsxAsync({
          buffer: file,
          fileName: "QIVIA_" + t("vehicles.title") + ".xlsx",
        }),
      );
    },
    [t, dispatch],
  );

  const columnsDisplayed: {
    [index in keyof VehicleElectrificationDisplayed]: MaterialUiColumnsProps;
  } = useMemo(() => {
    return {
      reference: {
        type: "string",
      },
      registrationNumber: {
        type: "string",
      },
      model: {
        type: "string",
      },
      startDate: {
        type: "date",
        valueGetter: getDate,
        valueFormatter: formatDate,
      },
      endDate: {
        type: "date",
        valueGetter: getDate,
        valueFormatter: formatDate,
      },
      prioritizationScore: {
        type: "number",
      },
      optimalBatteryCapacity: {
        type: "number",
        width: 220,
      },
      tripsScore: {
        type: "number",
      },
      distanceScore: {
        type: "number",
      },
      driverElectroCompatibilityScore: {
        type: "number",
        width: 270,
      },
    };
  }, []);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  if (params.tab !== "vehicles") {
    return;
  }

  return (
    <MaterialUiTable<
      keyof VehicleElectrificationDisplayed,
      VehicleElectrificationDisplayed
    >
      rows={listDisplayed}
      columns={columnsDisplayed}
      pageName={pageName}
      dataGridState={dataGridState}
      searchBar={searchBarProps}
      apiRef={apiRef}
      downloadViewAction={downloadViewAction}
    />
  );
};
