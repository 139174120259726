import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ArticleOutlined,
  Avatar,
  ButtonDS,
  DialogModalDS,
  EllipseMenu,
  EmailOutlined,
  FilePresentOutlined,
  LockOpenOutlined,
  LoopOutlined,
  MessageTooltip,
  RemoveRedEyeOutlined,
  SidePanelDS,
  Spacer,
  TableTagTransaction,
  Tag,
  TextCapitalized,
  TextsmsOutlined,
  dateFormatterDayMonthYear,
  dateFormatterDayMonthYearAndHourMinute2Digits,
  dateFormatterHourMinute,
  sortByDateTime,
  triggerToast,
} from "@qivia/ui";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  PublicTransactionsPropsType,
  ReasonsRefusedEnum,
} from "./transactionsAPI";
import {
  getLastReminderDateAsync,
  getSupportingDocument,
  selectAuthorizationsList,
  selectIsSupportingDocumentPdf,
  selectLastReminderDate,
  selectSupportingDocumentUrl,
  selectTransactionsList,
  sendSmsAsync,
  sendSmsReplaceInvalidAsync,
  transactionsSlice,
} from "./transactionsSlice";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { LittleCardAsset } from "@qivia/ui/src/assets/assets";
import { CategoriesMerchantCorrectionModal } from "./modals/categoriesMerchantCorrectionModal";

export const TransactionsSidePanel: FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams();

  const transactionsList: PublicTransactionsPropsType[] | null = useAppSelector(
    selectTransactionsList,
  );
  const authorizationsRefusedList: PublicTransactionsPropsType[] | null =
    useAppSelector(selectAuthorizationsList);

  const allTransactionsDisplayed: PublicTransactionsPropsType[] =
    useMemo(() => {
      if (!transactionsList || !authorizationsRefusedList) return [];
      return sortByDateTime(
        [...transactionsList, ...authorizationsRefusedList],
        "desc",
      );
    }, [authorizationsRefusedList, transactionsList]);

  const transaction = allTransactionsDisplayed.find(
    (t) => t.uuid === params.id,
  );

  useEffect(() => {
    if (!transaction) {
      navigate(`/home/transactions/cards`);
    }
  }, [navigate, transaction]);

  const onSidePanelClosed = useCallback(() => {
    navigate("/home/transactions/cards");
  }, [navigate]);

  if (!transaction) {
    return;
  }
  return (
    <SidePanelDS.SidePanel visible={!!transaction} onClose={onSidePanelClosed}>
      <SidePanelDS.Section
        verticalSpacing={1.5}
        horizontalSpacing={1.5}
        background={colors["colors/button/secondary/hover"]}
      >
        <SidePanelHeader transaction={transaction} />
        <Spacer y={1} />
        <SidePanelSectionBlock transaction={transaction} />
      </SidePanelDS.Section>
      <SidePanelDS.Section horizontalSpacing={1.5} verticalSpacing={2}>
        <SidePanelDetails transaction={transaction} />
      </SidePanelDS.Section>
    </SidePanelDS.SidePanel>
  );
};

const SidePanelSectionBlock = (props: {
  transaction: PublicTransactionsPropsType;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <SidePanelDS.ActionBlock>
      <>
        {props.transaction.driverName && props.transaction.driverLastName && (
          <SidePanelDS.SectionedActionStripe
            left={
              <StyledRowCenter>
                <TextCapitalized>
                  <Avatar
                    size={"S"}
                    color={"orange"}
                    firstName={props.transaction.driverName[0]}
                    lastName={props.transaction.driverLastName[0]}
                  />
                </TextCapitalized>
                <Spacer x={0.5} />
                {props.transaction.driverName}
              </StyledRowCenter>
            }
            right={
              <StyledLittleIcon>
                <RemoveRedEyeOutlined
                  key="driver"
                  onClick={() =>
                    navigate(`/home/drivers/${props.transaction.driver}`)
                  }
                />
                <Spacer x={1} />
              </StyledLittleIcon>
            }
          />
        )}
      </>
      <SidePanelDS.SectionedActionStripe
        left={
          <StyledRow>
            <LittleCardAsset />
            <Spacer x={1} />
            {`**** ${props.transaction.cardLast4Digits.toString()}`}
          </StyledRow>
        }
        right={
          <StyledLittleIcon>
            <RemoveRedEyeOutlined
              key="card"
              onClick={() =>
                navigate(`/home/cards/${props.transaction.cardUuid}`)
              }
            />
            <Spacer x={1} />
          </StyledLittleIcon>
        }
      />
      <SidePanelDS.SectionedActionStripe
        left={
          <StyledRowCenter>
            {props.transaction.registrationNumber}
            {props.transaction.vehicleReference && (
              <>
                <Spacer x={1} />
                <Tag
                  textColor={colors["colors/text/darkGrey"]}
                  text={props.transaction.vehicleReference}
                  backgroundColor={
                    colors["colors/surfaces/background/background_level0"]
                  }
                  borderColor={colors["colors/text/darkGrey"]}
                />
              </>
            )}
          </StyledRowCenter>
        }
        right={
          <StyledLittleIcon>
            <RemoveRedEyeOutlined
              key="vehicle"
              onClick={() =>
                navigate(`/home/vehicles/${props.transaction.vehicleUuid}`)
              }
            />
            <Spacer x={1} />
          </StyledLittleIcon>
        }
      />
      <SidePanelDS.SectionedActionStripe
        left={
          <StyledRowCenter>
            <StyledBodyM>
              <TextCapitalized>
                {t(`cards.sidePanel.informations.title.rule`)}
              </TextCapitalized>
            </StyledBodyM>
            <Spacer x={0.5} />
            {props.transaction.ruleName}
          </StyledRowCenter>
        }
        right={
          <StyledLittleIcon>
            <RemoveRedEyeOutlined
              key="rule"
              onClick={() =>
                navigate(`/home/rule/${props.transaction.ruleUuid}`)
              }
            />
            <Spacer x={1} />
          </StyledLittleIcon>
        }
      />
    </SidePanelDS.ActionBlock>
  );
};

const SidePanelHeader = (props: {
  transaction: PublicTransactionsPropsType;
}) => {
  const [displaySendSmsModal, setDisplaySendSmsModal] =
    useState<boolean>(false);
  const [
    isMerchantAuthorizationModalVisible,
    setIsMerchantAuthorizationModalVisible,
  ] = useState<boolean>(false);
  const { t } = useTranslation();

  const categoryCorrectionDisplayed =
    props.transaction.categoryAlreadyCorrected === false &&
    props.transaction.category === null;

  return (
    <StyledPanelContainer>
      <StyledHeader>
        <TextCapitalized>
          {props.transaction.category &&
            t(`transactions.category.${props.transaction.category}`)}
        </TextCapitalized>
      </StyledHeader>
      <Spacer y={0.5} />
      <TableTagTransaction
        status={t(`transactions.status.${props.transaction.status}`)}
      />
      <Spacer y={1.5} />
      <StyledRow>
        <StyledAmount $isCanceled={props.transaction.status === "CANCELED"}>
          {props.transaction.amount}
        </StyledAmount>
        <StyledCurrency>
          {props.transaction.amount && (
            <>
              &nbsp;EUR <Spacer y={0.25} />
            </>
          )}
        </StyledCurrency>
      </StyledRow>
      <Spacer y={1} />
      <StyledBodyM>{props.transaction.merchantName}</StyledBodyM>
      <Spacer y={0.25} />
      <StyledDate>
        <div>{dateFormatterDayMonthYear(new Date(props.transaction.date))}</div>
        <div>
          {dateFormatterHourMinute(new Date(props.transaction.date)).replace(
            ":",
            "h",
          )}{" "}
        </div>
      </StyledDate>
      {props.transaction.status !== "CANCELED" && (
        <>
          <Spacer y={1} />
          {(props.transaction.status === "ACCEPTED" ||
            props.transaction.status === "PENDING") &&
            props.transaction.supportingDocumentUuid && (
              <GetSupportingDocument
                supportingDocumentUuid={
                  props.transaction.supportingDocumentUuid
                }
                transaction={props.transaction}
              />
            )}

          {props.transaction.status === "REJECTED" &&
            props.transaction.reasonsRefused &&
            props.transaction.reasonsRefused.length > 0 && (
              <DisplayReasonsRefused
                reasonsRefused={props.transaction.reasonsRefused}
                setIsMerchantAuthorizationModalVisible={
                  setIsMerchantAuthorizationModalVisible
                }
                categoryCorrectionDisplayed={categoryCorrectionDisplayed}
              />
            )}
          {props.transaction.supportingDocumentStatus === "MISSING" &&
            props.transaction.areSupportingDocumentsMandatory &&
            (props.transaction.status === "ACCEPTED" ||
              props.transaction.status === "PENDING") &&
            props.transaction.driverPhone && (
              <SendSmsButton
                onClick={() => setDisplaySendSmsModal(true)}
                transactionUuid={props.transaction.uuid}
              />
            )}
          <SendSmsModal
            transaction={props.transaction}
            isModalOpen={displaySendSmsModal}
            onCloseModal={() => setDisplaySendSmsModal(false)}
          />
          <CategoriesMerchantCorrectionModal
            visible={isMerchantAuthorizationModalVisible}
            onCloseModal={() => setIsMerchantAuthorizationModalVisible(false)}
            transaction={props.transaction}
          />
        </>
      )}
    </StyledPanelContainer>
  );
};

const SidePanelDetails = (props: {
  transaction: PublicTransactionsPropsType;
}) => {
  const { t } = useTranslation();
  const hasUnits = (label: string) => {
    return ["mileage", "quantity", "consumption", "carbonEmission"].includes(
      label,
    );
  };
  const transactionInformations = [
    "merchantCity",
    "fuel",
    "mileage",
    "quantity",
    "consumption",
    "carbonEmission",
  ];

  return (
    <>
      <TextCapitalized>
        <StyledLabel>{t(`transactions.informations.label.title`)}</StyledLabel>
      </TextCapitalized>
      {transactionInformations.map((informationLabel) => {
        const informationDisplay =
          props.transaction[
            informationLabel as keyof PublicTransactionsPropsType
          ];

        return (
          <div key={informationLabel}>
            <Spacer y={2} />
            <SidePanelDS.SectionRow
              label={
                <TextCapitalized>
                  <StyledInformations key={informationLabel}>
                    {t(`transactions.informations.label.${informationLabel}`)}
                  </StyledInformations>
                </TextCapitalized>
              }
              value={
                <TextCapitalized>
                  <StyledInformations key={informationLabel}>
                    {!informationDisplay ||
                    informationDisplay.toString() === "0" ? (
                      "-"
                    ) : (
                      <StyledRow>
                        {informationDisplay}
                        <Spacer x={0.25} />
                        {hasUnits(informationLabel) && (
                          <StyledUnits>
                            <TextCapitalized>
                              {t(
                                `transactions.informations.units.${informationLabel}`,
                              )}
                            </TextCapitalized>
                          </StyledUnits>
                        )}
                      </StyledRow>
                    )}
                  </StyledInformations>
                </TextCapitalized>
              }
            />
          </div>
        );
      })}
    </>
  );
};

const SendSmsButton = (props: {
  onClick: () => void;
  transactionUuid: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const lastReminderDate = useAppSelector(selectLastReminderDate);

  const displayToasterError = useCallback(() => {
    triggerToast(t(`transactions.sendSms.isLessThan48h.errorMessage`), "error");
  }, [t]);

  useEffect(() => {
    void dispatch(
      getLastReminderDateAsync({ transaction: props.transactionUuid }),
    );
  }, [dispatch, props.transactionUuid]);

  const isRemindeLessThan48h = useMemo(() => {
    const date48hAgo = new Date();
    date48hAgo.setDate(date48hAgo.getDate() - 2);
    return !!(lastReminderDate && lastReminderDate > date48hAgo);
  }, [lastReminderDate]);

  return (
    <MessageTooltip
      title={t(`transactions.sendSms.title`)}
      text={
        (isRemindeLessThan48h &&
          lastReminderDate &&
          t(`transactions.sendSms.isLessThan48h`, {
            date: dateFormatterDayMonthYearAndHourMinute2Digits(
              new Date(lastReminderDate),
            ),
          })) ||
        ""
      }
      type={"ERROR"}
      button={
        <ButtonDS
          format="fill"
          buttonType="primary"
          text={t(`transactions.sendSms.button`)}
          leftIcon={<LoopOutlined />}
          onClick={() =>
            isRemindeLessThan48h ? displayToasterError() : props.onClick()
          }
          disabled={isRemindeLessThan48h}
        />
      }
    />
  );
};

const SendSmsModal = (props: {
  transaction: PublicTransactionsPropsType;
  isModalOpen: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <DialogModalDS
      visible={props.isModalOpen}
      onClose={props.onCloseModal}
      bottomButtons={{
        leftButton: (
          <ButtonDS
            text={t("transactions.sendSms.modal.cancel")}
            format={"fill"}
            buttonType={"secondary"}
            onClick={() => {
              props.onCloseModal();
            }}
          />
        ),
        rightButton: (
          <ButtonDS
            text={t("transactions.sendSms.modal.send")}
            format={"fill"}
            buttonType={"primary"}
            rightIcon={<EmailOutlined />}
            disabled={!props.transaction.driverName}
            onClick={() => {
              props.transaction.driverPhone &&
                void dispatch(
                  sendSmsAsync({
                    driverPhone: props.transaction.driverPhone,
                    driverName: props.transaction.driverName ?? "",
                    transactionUuid: props.transaction.uuid,
                  }),
                );
              props.onCloseModal();
            }}
          />
        ),
      }}
    >
      <StyledSmsModal>
        <StyledIcon>
          <TextsmsOutlined />
        </StyledIcon>
        <Spacer y={0.5} />
        <StyledSmsModalTitle>
          <TextCapitalized>
            {t("transactions.sendSms.modal.title")}
          </TextCapitalized>
        </StyledSmsModalTitle>
        <Spacer y={0.625} />
        <TextCapitalized>
          <div>{t("transactions.sendSms.modal.content")}</div>
        </TextCapitalized>
      </StyledSmsModal>
    </DialogModalDS>
  );
};

const GetSupportingDocument = (props: {
  supportingDocumentUuid: string;
  transaction: PublicTransactionsPropsType;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const supportingDocumentUrl = useAppSelector(selectSupportingDocumentUrl);
  const isSupportingDocumentPdf = useAppSelector(selectIsSupportingDocumentPdf);

  return (
    <>
      <ButtonDS
        format="fill"
        buttonType="secondary"
        text={t("transactions.informations.download")}
        leftIcon={<RemoveRedEyeOutlined />}
        onClick={() =>
          void dispatch(getSupportingDocument(props.supportingDocumentUuid))
        }
      />
      {supportingDocumentUrl && (
        <DialogModalDS
          visible={!!supportingDocumentUrl}
          onClose={() => {
            dispatch(transactionsSlice.actions.resetSupportingDocumentUrl());
          }}
          title={t("transactions.informations.modal.title")}
          subTitle={t("transactions.informations.modal.subTitle")}
          iconTitle={<FilePresentOutlined />}
        >
          <StyledModal>
            {isSupportingDocumentPdf ? (
              <iframe src={supportingDocumentUrl} />
            ) : (
              <StyledImage src={supportingDocumentUrl} />
            )}
            <Spacer y={1.5} />
            <MessageTooltip
              title={t(
                "transactions.informations.modal.message.invalid.document.title",
              )}
              text={t(
                "transactions.informations.modal.message.invalid.document.text",
              )}
              type="INFORMATIVE"
              button={
                <ButtonDS
                  buttonType="secondary"
                  format="fill"
                  leftIcon={<ArticleOutlined />}
                  text={t(
                    "transactions.informations.modal.message.invalid.document.button",
                  )}
                  disabled={!props.transaction.driverName}
                  onClick={() => {
                    props.transaction.driverPhone &&
                      void dispatch(
                        sendSmsReplaceInvalidAsync({
                          driverPhone: props.transaction.driverPhone,
                          driverName: props.transaction.driverName ?? "",
                          transactionUuid: props.transaction.uuid,
                        }),
                      );
                    dispatch(
                      transactionsSlice.actions.resetTransactionsListStatus(),
                    );
                  }}
                />
              }
            />
          </StyledModal>
        </DialogModalDS>
      )}
    </>
  );
};

const DisplayReasonsRefused = (props: {
  reasonsRefused: ReasonsRefusedEnum[];
  setIsMerchantAuthorizationModalVisible: (value: boolean) => void;
  categoryCorrectionDisplayed: boolean;
}) => {
  const { t } = useTranslation();
  const [reasonIndex, setReasonIndex] = useState<number>(0);
  const primaryRefusedReasons: ReasonsRefusedEnum[] = useMemo(() => {
    return [
      "IN_OPPOSITION_CARD",
      "INACTIVE_CARD",
      "EARLY_RETRY",
      "ROTATION_REQUEST_TRANSACTIONS_EXCEEDED",
      "ALREADY_REJECTED_ROTATION_REQUEST",
    ];
  }, []);
  const primaryRefusedReason = useMemo(
    () =>
      primaryRefusedReasons.filter((reason) =>
        props.reasonsRefused.includes(reason),
      )[0],
    [primaryRefusedReasons, props.reasonsRefused],
  );

  if (primaryRefusedReason) {
    return (
      <MessageTooltip
        title={t(
          `transactions.reasonsRefused.${primaryRefusedReason.toLowerCase()}.title`,
        )}
        text={t(
          `transactions.reasonsRefused.${primaryRefusedReason.toLowerCase()}.text`,
        )}
        type={"ERROR"}
      />
    );
  }

  const reasonsRefusedSorted = ReasonsRefusedDict.filter((reason) =>
    props.reasonsRefused.includes(reason as ReasonsRefusedEnum),
  );

  if (reasonsRefusedSorted.length < 1) return;

  const isOnlyOneReasonRefused = reasonsRefusedSorted.length === 1;

  return (
    <>
      <Spacer y={2} />
      <MessageTooltip
        title={t(
          `transactions.reasonsRefused.${reasonsRefusedSorted[
            reasonIndex
          ].toLowerCase()}.title`,
        )}
        text={t(
          `transactions.reasonsRefused.${reasonsRefusedSorted[
            reasonIndex
          ].toLowerCase()}.text`,
        )}
        type={"ERROR"}
        button={
          props.categoryCorrectionDisplayed &&
          reasonsRefusedSorted[reasonIndex] === "UNAUTHORIZE_MERCHANT" ? (
            <ButtonDS
              text={t(`transactions.reasonsRefused.button.authorizedMerchant`)}
              format={"fill"}
              buttonType={"primary"}
              leftIcon={<LockOpenOutlined />}
              onClick={() => props.setIsMerchantAuthorizationModalVisible(true)}
            />
          ) : undefined
        }
      />
      {!isOnlyOneReasonRefused && (
        <EllipseMenu
          sections={reasonsRefusedSorted}
          selectedSection={reasonIndex}
          setSelectedSection={setReasonIndex}
        />
      )}
    </>
  );
};

const ReasonsRefusedDict = [
  "NO_DRIVER",
  "EXCEEDED_LIMITS",
  "NO_FUNDS",
  "UNAUTHORIZE_MERCHANT",
  "WRONG_PLACE",
  "WRONG_DAY",
  "WRONG_TIME",
];

const StyledModal = styled.div`
  width: 100%;
  iframe {
    min-width: 100%;
    height: 45rem;
    border-radius: 0.5rem;
  }
`;

const StyledImage = styled.img`
  border-radius: 0.5rem;
  width: 100%;
`;

const StyledLabel = styled.div`
  ${typographies["Body/L"]}
  color: ${colors["colors/text/darkGrey"]};
`;
const StyledUnits = styled.div`
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/darkGrey"]};
`;
const StyledHeader = styled.div`
  ${typographies["Header/H4"]}
`;

const StyledInformations = styled.div`
  ${typographies["Body/L"]};
  color: ${colors["colors/text/black"]};
`;

const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/darkGrey"]}
`;
const StyledRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledSmsModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors["colors/text/darkGrey"]};
  ${typographies["Body/S"]};
  text-align: center;
`;

const StyledIcon = styled.div`
  & svg {
    width: 4.25rem;
    height: 4.25rem;
    fill: ${colors["colors/system/informative/informative_normal"]};
  }
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  border-radius: 0.6875rem;
  padding: 0.5rem;
`;
const StyledSmsModalTitle = styled.div`
  ${typographies["Header/H3"]}
  color: ${colors["colors/text/black"]};
`;

//NO TYPO
const StyledAmount = styled.div<{
  $isCanceled?: boolean;
}>`
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  font-family: Inter;
  text-decoration: ${({ $isCanceled }) =>
    $isCanceled ? "line-through" : "none"};
`;
//NO TYPO
const StyledCurrency = styled.div`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  font-family: Inter;
`;

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
const StyledLittleIcon = styled.div`
  display: flex;
  cursor: pointer;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledBodyM = styled.div`
  ${typographies["Body/M"]}
`;
