import { useCallback, useEffect, useState } from "react";
import { BadgeInOppositionModal } from "./inOppositionModal";
import { BadgeOverviewModal } from "./overviewModal";
import { BadgeDeliveryAddressModal } from "./deliveryAddressModal";
import { BadgeAddressInput } from "../../badgesAPI";

export type InOppositionSteps =
  | "IN_OPPOSITION"
  | "DELIVERY_ADDRESS"
  | "OVERVIEW";
export type InOppositionOptions =
  | "IN_OPPOSITION"
  | "IN_OPPOSITION_AND_ORDER"
  | "RETURN"
  | "RETURN_AND_REPLACE";

export const StepsToInOppositionBadge = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
  badge: { number: string; uuid: string };
  vehicle: {
    registrationNumber: string;
    reference: string;
    uuid: string;
  };
  companyUuid: string;
}) => {
  const { onCloseModal } = props;
  const [step, setStep] = useState<InOppositionSteps>("IN_OPPOSITION");
  const [option, setOption] = useState<InOppositionOptions>("IN_OPPOSITION");
  const [addressFilled, setAddressFilled] = useState<BadgeAddressInput | null>(
    null,
  );

  const handleNewStep = useCallback((option: InOppositionOptions) => {
    setOption(option);
    if (option === "IN_OPPOSITION" || option === "RETURN") {
      setStep("OVERVIEW");
    } else {
      setAddressFilled(null);
      setStep("DELIVERY_ADDRESS");
    }
  }, []);

  const closeSteps = useCallback(() => {
    setStep("IN_OPPOSITION");
    onCloseModal();
  }, [onCloseModal]);

  useEffect(() => {
    if (addressFilled) {
      setStep("OVERVIEW");
    }
  }, [addressFilled]);

  return (
    <>
      {step === "IN_OPPOSITION" && (
        <BadgeInOppositionModal
          isVisible={props.isVisible}
          onCloseModal={closeSteps}
          nextStep={handleNewStep}
        />
      )}
      {step === "DELIVERY_ADDRESS" && (
        <BadgeDeliveryAddressModal
          isVisible={props.isVisible}
          onCloseModal={closeSteps}
          setAddressFilled={setAddressFilled}
        />
      )}
      {step === "OVERVIEW" && (
        <BadgeOverviewModal
          isVisible={props.isVisible}
          onCloseModal={closeSteps}
          badge={props.badge}
          vehicle={props.vehicle}
          option={option}
          address={addressFilled}
        />
      )}
    </>
  );
};
