import { DataCard, Spacer, TextCapitalized } from "@qivia/ui";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { MultipleDrivers } from "@qivia/ui/src/assets/assets";

type VehiclesHeaderType = "electrifiableVehicles" | "shareOfElectrifiable";
const vehiclesHeaderData: VehiclesHeaderType[] = [
  "electrifiableVehicles",
  "shareOfElectrifiable",
];
type DriversHeaderType =
  | "superElectroCompatible"
  | "averageElectroCompatibleScore"
  | "shareOfDriversAnalyzed";

const driversHeaderDatas: DriversHeaderType[] = [
  "superElectroCompatible",
  "averageElectroCompatibleScore",
  "shareOfDriversAnalyzed",
];

const vehiclesFakeData: Record<string, number> = {
  electrifiableVehicles: 3,
  shareOfElectrifiable: 75,
};

const driversFakeData: Record<string, number> = {
  superElectroCompatible: 3,
  averageElectroCompatibleScore: 66,
  shareOfDriversAnalyzed: 75,
};

export const ElectrificationHeader = () => {
  const params = useParams();
  const { t } = useTranslation();

  if (!params.tab) {
    return;
  }

  //TODO HAVE REAL DATA LATER
  const data = params.tab === "vehicles" ? vehiclesFakeData : driversFakeData;

  const headerData =
    params.tab === "vehicles" ? vehiclesHeaderData : driversHeaderDatas;
  const titleElement =
    params.tab === "vehicles" ? (
      <img
        src={Car}
        style={{ width: "2.2rem", filter: "grayscale(100%)" }}
        alt="Car"
      />
    ) : (
      <MultipleDrivers />
    );

  return (
    <StyledHeader>
      <Spacer y={1.5} />
      <StyledFlex>
        <Spacer x={1.5} />
        <StyledLeft>
          <TextCapitalized>
            {t(`electrification.header.title.${params.tab}`)}
          </TextCapitalized>
          <TextCapitalized>
            {t(`electrification.header.title.${params.tab}.part2`)}
          </TextCapitalized>
        </StyledLeft>
        <StyledRight>
          <ContentHeader
            data={data}
            headerData={headerData}
            titleElement={titleElement}
          />
        </StyledRight>
        <Spacer x={1.5} />
      </StyledFlex>
      <Spacer y={1.5} />
    </StyledHeader>
  );
};

const ContentHeader = (payload: {
  titleElement: JSX.Element;
  data: Record<string, number>;
  headerData: VehiclesHeaderType[] | DriversHeaderType[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      {payload.headerData.map((v, index) => {
        return (
          <StyledFlex key={index + v}>
            {index !== 0 && <Spacer x={1.5} />}
            <DataCard
              width={19}
              titleElement={payload.titleElement}
              title={t(`electrification.header.${v}`)}
              titleHeight={typographies["Body/XS"]}
              backgroundColor="white"
              content={
                <StyledFlex>
                  {payload.data[v]}
                  <Spacer x={0.5} />
                  {t(`electrification.header.${v}.unit`)}
                </StyledFlex>
              }
            />
          </StyledFlex>
        );
      })}
    </>
  );
};

const StyledHeader = styled.div`
  border-radius: 0.5rem;
  background: ${colors["colors/surfaces/background/background_level1"]};
  width: 100%;
  font-size: 1.75rem;
  font-weight: 400;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledLeft = styled.div`
  min-width: 25rem;
`;

const StyledRight = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
`;
