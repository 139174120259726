import { colors } from "../../../styles/figmaColors";
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { TextCapitalized } from "../text/TextCapitalized";
import styled from "styled-components";
import { typographies } from "../../../styles/figmaTypographies";
import { Spacer } from "../Spacer";
import { CustomTick } from "./Custom/CustomTick";
import { CustomLineChartLegend } from "./Custom/CustomLineChartLegend";
import { adjustYaxisDomain } from "../../../utils";

interface LineChartData {
  date: string;
  value: number;
}

interface LineChartDSProps {
  transactions: LineChartData[];
  legend: string;
  unit: string;
}

interface TooltipPayload {
  payload: LineChartData;
}

export const LineChartDS = (props: LineChartDSProps) => {
  const startDate = props.transactions[0].date;
  const endDate = props.transactions[props.transactions.length - 1].date;
  const maxValue = Math.max(...props.transactions.map((t) => t.value));
  const minValue = Math.min(...props.transactions.map((t) => t.value));

  return (
    <ResponsiveContainer>
      <LineChart data={props.transactions}>
        <XAxis
          dataKey="date"
          ticks={startDate !== endDate ? [startDate, endDate] : [startDate]}
          tick={(tickProps) => <CustomTick {...tickProps} />}
          tickLine={false}
          tickMargin={15}
        />
        <YAxis
          domain={adjustYaxisDomain(minValue, maxValue)}
          tick={(tickProps) => <CustomTick {...tickProps} />}
          tickMargin={15}
          padding={{ top: 8 }}
        />
        <Tooltip content={<CustomTooltip unit={props.unit} />} />
        <Legend content={<CustomLineChartLegend />} />
        <Line
          name={props.legend}
          dataKey="value"
          stroke={colors["colors/system/informative/informative_light"]}
          strokeWidth={2}
          legendType="plainline"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({
  active,
  payload,
  unit,
}: {
  active?: boolean;
  payload?: TooltipPayload[];
  unit: string;
}) => {
  const color = colors["colors/system/informative/informative_light"];
  if (active && payload && payload.length > 0) {
    return (
      <StyledTooltip>
        <Spacer y={0.75} />
        <StyledRow>
          <Spacer x={0.75} />
          {payload.map(({ payload: { date, value } }, index) => (
            <div key={index + "tootlip"}>
              <StyledColor color={color}>
                <TextCapitalized>{date}</TextCapitalized>
              </StyledColor>
              {value.toString()} {unit}
            </div>
          ))}
          <Spacer x={0.75} />
        </StyledRow>
        <Spacer y={0.75} />
      </StyledTooltip>
    );
  }
  return null;
};

const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  ${typographies["Body/L"]}
  color: ${colors["colors/text/black"]};
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledColor = styled.div<{ color: string }>`
  color: ${({ color }) => color};
`;
