import QiviaLogoSmall from "../../assets/QiviaLogoSmall.svg?react";
import QiviaLogoFull from "../../assets/QiviaLogoFull.svg?react";

import styled from "styled-components";
// import { KeyboardDoubleArrowRightSharp } from "../../materialUi/materialUi";
// import { KeyboardDoubleArrowLeftSharp } from "../../materialUi/materialUi";

import { Spacer } from "../Spacer";

type ComponentSize = "S" | "L";

export interface SideBarIconProps {
  size: ComponentSize;
  onClickArrow: () => void;
  onClickLogo: () => void;
}

export interface SideBarClickProps {
  onClickArrow: () => void;
  onClickLogo: () => void;
}

export const SmallSideBarIcon = (props: SideBarClickProps) => (
  <SmallStyledContainer>
    <StyledLogo>
      <QiviaLogoSmall onClick={props.onClickLogo} />
    </StyledLogo>
    <Spacer y={0.625} />
    {/* <StyledArrowContainer onClick={props.onClickArrow}>
      <KeyboardDoubleArrowRightSharp />
    </StyledArrowContainer> */}
  </SmallStyledContainer>
);

export const LargeSideBarIcon = (props: SideBarClickProps) => (
  <LargeStyledContainer>
    <StyledLogo>
      <QiviaLogoFull onClick={props.onClickLogo} />
    </StyledLogo>
    <StyledSpacer />
    {/* <StyledArrowContainer onClick={props.onClickArrow}>
      <KeyboardDoubleArrowLeftSharp />
    </StyledArrowContainer> */}
    <Spacer x={1} />
  </LargeStyledContainer>
);

export const SideBarIcon = (props: SideBarIconProps) =>
  props.size === "S" ? (
    <SmallSideBarIcon
      onClickArrow={props.onClickArrow}
      onClickLogo={props.onClickLogo}
    />
  ) : (
    <LargeSideBarIcon
      onClickArrow={props.onClickArrow}
      onClickLogo={props.onClickLogo}
    />
  );

const StyledLogo = styled.div`
  display: flex;
  padding: 0.625rem;
  cursor: pointer;
`;

const StyledSpacer = styled.div`
  width: 100%;
`;

const SmallStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const LargeStyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// const StyledArrowContainer = styled.div`
//   height: 1.5rem;
//   width: 1.5rem;
//   & svg path {
//     fill: white;
//   }
//   cursor: pointer;
// `;
