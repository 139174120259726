import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { jwtDecode } from "jwt-decode";
import { loginAsync } from "../login/loginSlice";
import { registerAsync } from "../register/registerSlice";
import { authAxios, QueryStatus } from "../../utils";
import { updateUsersAsync, updateUsersPasswordAsync } from "./users/usersSlice";
import {
  CompaniesList,
  GetCompanyBalancePerPeriodType,
  UserRole,
  UserStatus,
} from "./homeAPI";
import * as Sentry from "@sentry/react";

export type OnboardingStatus = "NOT_STARTED" | "IN_PROGRESS" | "FINISHED";

export interface PublicCompany {
  uuid: string;
  name: string;
  providerId: string;
  providerOnboardingStatus: OnboardingStatus;
  canOrderCards: boolean;
  providerData: {
    requirements: {
      currently_due: Array<string>;
    };
  } | null;
  recipientsEmails: string[];
  iban: string;
  bic: string;
  balance: {
    currency: string;
    value: number;
  };
  bankDetailsBucketFileName: string | null;
  externalIban: string | null;
  connectSepaMandateSigned: boolean;
  platformSepaMandateSigned: boolean;
  canActivateConnectSepaDebit: boolean;
  canActivatePlatformSepaDebit: boolean;
  hasAnalytics: boolean;
  hasAdvancedAnalytics: boolean;
  canOrderBadges: boolean;
  qontoMandateSigned: boolean;
  memoBankMandateSigned: boolean;
}

export interface PublicManager {
  firstName: string;
  lastName: string;
  email: string;
  uuid: string;
  companyUuid: string;
  companyGroup: string;
  job: string;
  status: UserStatus;
  role: UserRole;
}

export interface HomeState {
  loadCompanyStatus: QueryStatus;
  loadAllCompaniesStatus: QueryStatus;
  healthCheckStatus: QueryStatus;
  isTokenDecoded: boolean;
  manager: PublicManager | null;
  company: PublicCompany | null;
  rawToken: string | null;
  companies: CompaniesList[];
  currentCompanyUuid: string | null;
  currentCompanyStatus: QueryStatus;
  balancePerPeriodStatus: QueryStatus;
  balancePerPeriod: GetCompanyBalancePerPeriodType | null;
}

const initialState: HomeState = {
  loadCompanyStatus: "idle",
  loadAllCompaniesStatus: "idle",
  healthCheckStatus: "idle",
  isTokenDecoded: false,
  company: null,
  manager: null,
  rawToken: null,
  companies: [],
  currentCompanyUuid: null,
  currentCompanyStatus: "idle",
  balancePerPeriodStatus: "idle",
  balancePerPeriod: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadCompanyAsync = createAsyncThunk(
  "loadCompany/call",
  async (payload: { uuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<PublicCompany>(`company/${payload.uuid}`);
    return response.data;
  },
);

export const loadAllCompaniesAsync = createAsyncThunk(
  "loadAllCompanies/call",
  async (payload: { companyGroupUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<CompaniesList[]>(
      `companies/company_group/${payload.companyGroupUuid}`,
    );
    return response.data.sort((a, b) => a.name.localeCompare(b.name));
  },
);

export const checkHealthAsync = createAsyncThunk(
  "checkHealth/call",
  async () => {
    const axios = authAxios();
    await axios.get(`login`);
  },
);

export const getCompanyBalancePerPeriodAsync = createAsyncThunk(
  "getCompanyBalancePerPeriod/call",
  async (payload: { companyUuid: string; date: string }) => {
    const axios = authAxios();
    const response = await axios.get<GetCompanyBalancePerPeriodType>(
      `balances/company/${payload.companyUuid}/per_period`,
      {
        params: {
          date: payload.date,
        },
      },
    );
    return response.data;
  },
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    decodeToken: (state) => {
      const token = localStorage.getItem("token");
      if (token) {
        state.rawToken = token;
        const decodedToken = jwtDecode<{
          manager: {
            firstName: string;
            lastName: string;
            email: string;
            uuid: string;
            companyUuid: string;
            companyGroup: string;
            job: string;
            status: UserStatus;
            role: UserRole;
          };
        }>(token);
        Sentry.setUser({
          username: decodedToken.manager.firstName.concat(
            " ",
            decodedToken.manager.lastName,
          ),
          email: decodedToken.manager.email,
        });
        state.manager = decodedToken.manager;
      } else state.manager = null;
      state.isTokenDecoded = true;
    },
    removeToken: (state) => {
      localStorage.removeItem("token");
      state.isTokenDecoded = false;
      state.manager = null;
    },
    removeCurrentCompany: (state) => {
      localStorage.removeItem("company");
      state.currentCompanyStatus = "idle";
    },
    removeCompany: (state) => {
      state.company = null;
    },
    getCurrentCompany: (state) => {
      const companyUuid = localStorage.getItem("company");
      state.currentCompanyUuid = companyUuid;
      state.currentCompanyStatus = "success";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadCompanyAsync.pending, (state) => {
        state.loadCompanyStatus = "processing";
      })
      .addCase(loadCompanyAsync.fulfilled, (state, action) => {
        state.company = action.payload;
        localStorage.setItem("company", action.payload.uuid);
        state.loadCompanyStatus = "success";
        state.currentCompanyStatus = "idle";
      })
      .addCase(loadCompanyAsync.rejected, (state) => {
        state.loadCompanyStatus = "failed";
      })
      .addCase(checkHealthAsync.pending, (state) => {
        state.healthCheckStatus = "processing";
      })
      .addCase(checkHealthAsync.fulfilled, (state) => {
        state.healthCheckStatus = "success";
      })
      .addCase(checkHealthAsync.rejected, (state) => {
        state.healthCheckStatus = "failed";
        localStorage.removeItem("token");
        state.manager = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload);
        state.isTokenDecoded = false;
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload);
        state.isTokenDecoded = false;
      })
      .addCase(updateUsersAsync.fulfilled, (state, action) => {
        if (action.payload) {
          localStorage.setItem("token", action.payload);
          state.isTokenDecoded = false;
        }
      })
      .addCase(updateUsersPasswordAsync.fulfilled, (state, action) => {
        if (action.payload) {
          localStorage.setItem("token", action.payload);
          state.isTokenDecoded = false;
        }
      })
      .addCase(getCompanyBalancePerPeriodAsync.pending, (state) => {
        state.balancePerPeriodStatus = "processing";
      })
      .addCase(getCompanyBalancePerPeriodAsync.fulfilled, (state, action) => {
        state.balancePerPeriod = action.payload;
        state.balancePerPeriodStatus = "success";
      })
      .addCase(getCompanyBalancePerPeriodAsync.rejected, (state) => {
        state.balancePerPeriodStatus = "failed";
      })
      .addCase(loadAllCompaniesAsync.pending, (state) => {
        state.loadAllCompaniesStatus = "processing";
      })
      .addCase(loadAllCompaniesAsync.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.loadAllCompaniesStatus = "success";
      })
      .addCase(loadAllCompaniesAsync.rejected, (state) => {
        state.loadAllCompaniesStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const {
  decodeToken,
  removeToken,
  getCurrentCompany,
  removeCurrentCompany,
  removeCompany,
} = homeSlice.actions;
export const selectLoadCompanyStatus = (state: RootState) =>
  state.home.loadCompanyStatus;
export const selectManager = (state: RootState) => state.home.manager;
export const selectCompany = (state: RootState) => state.home.company;
export const selectCompanyStatus = (state: RootState) =>
  state.home.loadCompanyStatus;
export const selectIsTokenDecoded = (state: RootState) =>
  state.home.isTokenDecoded;
export const selectRawToken = (state: RootState) => state.home.rawToken;
export const selectHealthCheckStatus = (state: RootState) =>
  state.home.healthCheckStatus;
export const selectAllCompanies = (state: RootState) => state.home.companies;
export const selectCurrentCompanyUuid = (state: RootState) =>
  state.home.currentCompanyUuid;
export const selectCurrentCompanyStatus = (state: RootState) =>
  state.home.currentCompanyStatus;
export const selectBalancePerPeriod = (state: RootState) =>
  state.home.balancePerPeriod;
export const selectBalancePerPeriodStatus = (state: RootState) =>
  state.home.balancePerPeriodStatus;

export default homeSlice.reducer;
