import {
  ButtonDS,
  Spacer,
  ArrowForwardOutlined,
  Badge,
  TextCapitalized,
  DataCard,
} from "@qivia/ui";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { useTranslation } from "react-i18next";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  driversAccessRequestsListAsync,
  selectCurrentDriverAccessRequest,
  selectDriverAccessRequestsList,
} from "./driversSlice";
import { useEffect } from "react";
import { selectCompany } from "../homeSlice";

export const DriverAccessRequestSummary = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const driverAccessRequestsList = useAppSelector(
    selectDriverAccessRequestsList,
  );

  const currentDriverAccessRequest = useAppSelector(
    selectCurrentDriverAccessRequest,
  );
  const company = useAppSelector(selectCompany);

  const numberOfDrivers = driverAccessRequestsList.length;

  useEffect(() => {
    if (company) {
      void dispatch(driversAccessRequestsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  return (
    <>
      {currentDriverAccessRequest && (
        <>
          <Spacer y={1} />
          <DataCard
            titleElement={
              <Badge
                text={t("drivers.accessRequest.badge")}
                backgroundColor={
                  colors["colors/system/warning/warning_veryLight"]
                }
                textColor={colors["colors/system/warning/warning_dark"]}
              />
            }
            title={
              numberOfDrivers === 1
                ? t("drivers.accessRequest.title.singular", {
                    numberOfDrivers,
                  })
                : numberOfDrivers > 1
                  ? t("drivers.accessRequest.title.plural", {
                      numberOfDrivers,
                    })
                  : ""
            }
            content={
              <StyledFlex>
                <StyledSubtitle>
                  <TextCapitalized>
                    {t("drivers.accessRequest.subtitle")}
                  </TextCapitalized>
                </StyledSubtitle>
                <Spacer x={1} />
                <StyledButtonColumn>
                  <ButtonDS
                    text={t("drivers.accessRequest.cta")}
                    format={"hug"}
                    buttonType={"primary"}
                    rightIcon={<ArrowForwardOutlined />}
                    onClick={() =>
                      navigate(
                        `/home/drivers/access_request/${currentDriverAccessRequest.uuid}`,
                      )
                    }
                  />
                </StyledButtonColumn>
              </StyledFlex>
            }
          />
        </>
      )}
    </>
  );
};

const StyledButtonColumn = styled.div`
  display: flex;
  align-items: flex-end;
  min-width: 11rem;
`;

const StyledSubtitle = styled.div`
  display: flex;
  ${typographies["Body/S"]};
  align-items: center;
  max-width: 30.375rem;
`;

const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;
