import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Assets,
  Avatar,
  ButtonDS,
  LockOutlined,
  MessageTooltip,
  NotInterestedOutlined,
  RemoveRedEyeOutlined,
  SelectDS,
  SidePanelDS,
  Spacer,
  Switch,
  TableTagCard,
  Tag,
  TextCapitalized,
  triggerToast,
} from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectCardUpdatedStatus,
  selectCardsList,
  selectRuleUpdated,
  selectRuleUpdatedStatus,
  updateCardStatusAsync,
  updateVehicleRuleAsync,
} from "./cardsSlice";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { SetCardInOppositionModal } from "./modals/cards/inOppositionModal";
import { selectRulesList } from "../rules/rulesSlice";
import { QrCodeInfo } from "./cardsAPI";
import CardsQrCodesModal from "./modals/cards/cardsQrCodesModal";
import { VehicleType } from "../vehicles/vehiclesAPI";

export const CardSidePanel: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const params = useParams();
  const cardsList = useAppSelector(selectCardsList);
  const card = cardsList.find((c) => c.uuid === params.id);
  const cardUpdatedStatus = useAppSelector(selectCardUpdatedStatus);
  const ruleNewStatus = useAppSelector(selectRuleUpdated);

  const [isInOppositionModalVisible, setIsInOppositionModalVisible] =
    useState(false);

  const isCardLocked = useMemo(() => {
    return card?.status === "LOCKED";
  }, [card?.status]);

  useEffect(() => {
    if (!card) {
      navigate(`/home/cards/cards`);
    }
  }, [navigate, card]);

  const lockCard = useCallback(
    (isCardLocked: boolean, cardUuid: string) => {
      void dispatch(
        updateCardStatusAsync({
          cardUuid,
          status: isCardLocked ? "LOCKED" : "UNLOCKED",
        }),
      );
    },
    [dispatch],
  );

  const onSidePanelClosed = useCallback(() => {
    navigate("/home/cards/cards");
  }, [navigate]);

  useEffect(() => {
    if (cardUpdatedStatus === "success" && ruleNewStatus) {
      triggerToast(
        t(`cards.updatedStatus.${ruleNewStatus}.success`) || "",
        "valid",
      );
    } else if (cardUpdatedStatus === "failed") {
      triggerToast(t("cards.updatedStatus.failure") || "", "error");
    }
  }, [ruleNewStatus, cardUpdatedStatus, t]);

  const isInOpposition = useMemo(
    () => (card && card.status === "IN_OPPOSITION") ?? false,
    [card],
  );

  if (!card) {
    return;
  }
  return (
    <SidePanelDS.SidePanel
      visible={!!card}
      onClose={onSidePanelClosed}
      absoluteSection={
        <ButtonDS
          leftIcon={
            <StyledIconColor $isInOpposition={isInOpposition}>
              <NotInterestedOutlined />
            </StyledIconColor>
          }
          text={t("cards.sidePanel.opposition")}
          format={"fill"}
          buttonType={"primary"}
          onClick={() =>
            !isInOpposition &&
            setIsInOppositionModalVisible(!isInOppositionModalVisible)
          }
          iconColor={"errorNormal"}
          disabled={isInOpposition}
        />
      }
    >
      <SidePanelDS.Section
        verticalSpacing={1.5}
        background={colors["colors/button/secondary/hover"]}
      >
        <StyledPanelContainer>
          <TextCapitalized>
            {t("cards.sidePanel.number")} **** {card.last4Digits ?? ""}
          </TextCapitalized>
          <Spacer y={1.5} />
          <TableTagCard status={t(`cards.status.${card.status}`)} />
          <Spacer y={1} />
          {isInOpposition ? (
            <>
              <StyledAssets>
                <Assets.UnlockedQiviaCard />
              </StyledAssets>
              <Spacer y={1} />
              <MessageTooltip
                title={t("cards.updatedStatus.IN_OPPOSITION.success")}
                text={t("cards.updatedStatus.IN_OPPOSITION.text")}
                type={"ERROR"}
              />
            </>
          ) : (
            <InformationsCard
              isCardLocked={isCardLocked ?? false}
              lockCard={(isCardLocked: boolean, cardUuid: string) =>
                lockCard(isCardLocked, cardUuid)
              }
              vehicle={{
                name: card.registrationNumber,
                uuid: card.vehicleUuid,
                reference: card.vehicleReference,
                type: card.vehicleType,
                model: [card.vehicleBrand, card.vehicleModel]
                  .filter((d) => d)
                  .join(" "),
              }}
              rule={{
                name: card.ruleName,
                uuid: card.ruleUuid,
              }}
              driver={{
                name: card.driverFirstName ?? "",
                lastName: card.driverLastName ?? "",
                uuid: card.driverUuid,
              }}
              cardUuid={card.uuid}
              qrCodes={card.qrCodes}
            />
          )}
        </StyledPanelContainer>
      </SidePanelDS.Section>
      <AmountLimitSectionsCard
        isInOpposition={isInOpposition}
        monthlyAmountlimit={card.monthlyAmountlimit}
        monthlyExpenses={card.monthlyExpenses}
      />
      <ParametersSectionsCard
        vehicleUuid={card.vehicleUuid}
        isInOpposition={isInOpposition}
        currentRule={card.ruleUuid}
      />
      <SetCardInOppositionModal
        isVisible={isInOppositionModalVisible}
        onCloseModal={() => {
          setIsInOppositionModalVisible(false);
        }}
        cardUuid={card.uuid}
      />
    </SidePanelDS.SidePanel>
  );
};

const ParametersSectionsCard = (props: {
  isInOpposition: boolean;
  currentRule: string;
  vehicleUuid: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const rules = useAppSelector(selectRulesList);
  const ruleUpdatedStatus = useAppSelector(selectRuleUpdatedStatus);

  const optionRule = useMemo(
    () =>
      rules.map((rule) => {
        return {
          value: rule.uuid,
          label: rule.name,
          disabled: rule.uuid === props.currentRule,
        };
      }),
    [props.currentRule, rules],
  );

  const valueOptionRule = useMemo(
    () => optionRule.find((option) => option.value === props.currentRule),
    [optionRule, props.currentRule],
  );

  useEffect(() => {
    if (ruleUpdatedStatus === "success") {
      triggerToast(t(`cards.updatedRule.success`) || "", "valid");
    } else if (ruleUpdatedStatus === "failed") {
      triggerToast(t("cards.updatedStatus.failure") || "", "error");
    }
  }, [ruleUpdatedStatus, t]);

  if (props.isInOpposition) {
    return;
  }

  return (
    <SidePanelDS.Section verticalSpacing={2} horizontalSpacing={1.5}>
      <StyledTitle>
        <TextCapitalized>
          {t("cards.sidePanel.informations.title.parameter")}
        </TextCapitalized>
      </StyledTitle>
      <Spacer y={2} />
      <SidePanelDS.SectionRow
        label={
          <StyledSubTitle>
            <TextCapitalized>
              {t("cards.sidePanel.informations.title.rule")}
            </TextCapitalized>
          </StyledSubTitle>
        }
        value={
          <StyledSelect>
            <SelectDS
              label={""}
              placeholder={t("cards.modal.rule.placeholder") || ""}
              value={valueOptionRule}
              options={optionRule}
              onChange={(selectedOption) => {
                selectedOption &&
                  void dispatch(
                    updateVehicleRuleAsync({
                      vehicleUuid: props.vehicleUuid,
                      ruleUuid: selectedOption.value,
                    }),
                  );
              }}
            />
          </StyledSelect>
        }
      />
    </SidePanelDS.Section>
  );
};

const AmountLimitSectionsCard = (props: {
  isInOpposition: boolean;
  monthlyAmountlimit: number;
  monthlyExpenses: number | null;
}) => {
  const { t } = useTranslation();

  if (props.isInOpposition) {
    return;
  }

  return (
    <SidePanelDS.Section verticalSpacing={2} horizontalSpacing={1.5}>
      <StyledTitle>
        <TextCapitalized>
          {t("cards.sidePanel.informations.title.amountLimit")}
        </TextCapitalized>
      </StyledTitle>
      <Spacer y={2} />
      <SidePanelDS.SectionRow
        label={
          <StyledSubTitle>
            <TextCapitalized>
              {t("cards.sidePanel.informations.title.expenses")}
            </TextCapitalized>
          </StyledSubTitle>
        }
        value={
          <StyledRow>
            <StyledSubTitle>
              {props.monthlyExpenses ?? 0} / {props.monthlyAmountlimit ?? 0}
            </StyledSubTitle>
            <Spacer x={0.5} />
            <StyledUnits>EUR</StyledUnits>
          </StyledRow>
        }
      />
      <Spacer y={1.5} />
      <StyledLine
        $percentage={
          props.monthlyExpenses
            ? (props.monthlyExpenses / props.monthlyAmountlimit) * 100
            : 0
        }
      />
    </SidePanelDS.Section>
  );
};

const StyledLine = styled.div<{ $percentage: number }>`
  width: 100%;
  border-bottom: 0.25rem solid ${colors["colors/borders/button/primary"]};
  border-radius: 2rem;
  border-image: linear-gradient(
      to right,
      ${colors["colors/accent/500"]} ${({ $percentage }) => $percentage}%,
      ${colors["colors/borders/button/primary"]}
        ${({ $percentage }) => $percentage}%
    )
    5;
`;

const InformationsCard = (props: {
  isCardLocked: boolean;
  lockCard: (isCardLocked: boolean, cardUuid: string) => void;
  vehicle: VehicleInformation;
  rule: Informations;
  driver: Informations;
  cardUuid: string;
  qrCodes: QrCodeInfo[];
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);

  const qrCodeButtonText = useMemo(
    () =>
      props.qrCodes.length > 1
        ? t("cards.sidePanel.button.openQrCodes")
        : t("cards.sidePanel.button.openQrCode"),
    [props.qrCodes.length, t],
  );

  return (
    <SidePanelDS.Section>
      <StyledAssets>
        {props.isCardLocked ? (
          <Assets.LockedQiviaCard />
        ) : (
          <Assets.UnlockedQiviaCard />
        )}
      </StyledAssets>
      <Spacer y={1.5} />
      <SidePanelDS.ActionBlock>
        <SidePanelDS.SectionedActionStripe
          left={
            <StyledRowCenter>
              <LockOutlined />
              <Spacer x={0.25} />
              <TextCapitalized>{t("cards.sidePanel.lock")}</TextCapitalized>
            </StyledRowCenter>
          }
          right={
            <Switch
              onClick={() =>
                props.lockCard(!props.isCardLocked, props.cardUuid)
              }
              checked={props.isCardLocked || false}
              size={"S"}
            />
          }
        />
      </SidePanelDS.ActionBlock>
      <Spacer y={1} />
      <SidePanelDS.ActionBlock>
        <>
          {props.driver.name && props.driver.lastName && (
            <SidePanelDS.SectionedActionStripe
              left={
                <StyledRowCenter>
                  <TextCapitalized>
                    <Avatar
                      size={"S"}
                      color={"orange"}
                      firstName={props.driver.name[0]}
                      lastName={props.driver.lastName[0]}
                    />
                  </TextCapitalized>
                  <Spacer x={0.5} />
                  {props.driver.name + " " + props.driver.lastName}
                </StyledRowCenter>
              }
              right={
                <StyledLittleIcon>
                  <RemoveRedEyeOutlined
                    key="driver"
                    onClick={() =>
                      navigate(`/home/drivers/${props.driver.uuid}`)
                    }
                  />
                  <Spacer x={1} />
                </StyledLittleIcon>
              }
            />
          )}
        </>
        <SidePanelDS.SectionedActionStripe
          left={
            <StyledRowCenter>
              {props.vehicle.name}
              {props.vehicle.reference && (
                <>
                  <Spacer x={1} />
                  <Tag
                    textColor={colors["colors/text/darkGrey"]}
                    text={props.vehicle.reference}
                    backgroundColor={
                      colors["colors/surfaces/background/background_level0"]
                    }
                    borderColor={colors["colors/text/darkGrey"]}
                  />
                </>
              )}
            </StyledRowCenter>
          }
          right={
            <StyledLittleIcon>
              <RemoveRedEyeOutlined
                key="vehicle"
                onClick={() => navigate(`/home/vehicles/${props.vehicle.uuid}`)}
              />
              <Spacer x={1} />
            </StyledLittleIcon>
          }
        />
        <SidePanelDS.SectionedActionStripe
          left={
            <StyledRowCenter>
              <StyledBodyM>
                <TextCapitalized>
                  {t(`cards.sidePanel.informations.title.rule`)}
                </TextCapitalized>
              </StyledBodyM>
              <Spacer x={0.5} />
              {props.rule.name}
            </StyledRowCenter>
          }
          right={
            <StyledLittleIcon>
              <RemoveRedEyeOutlined
                key="rule"
                onClick={() => navigate(`/home/rule/${props.rule.uuid}`)}
              />
              <Spacer x={1} />
            </StyledLittleIcon>
          }
        />
      </SidePanelDS.ActionBlock>
      <Spacer y={1} />
      <ButtonDS
        buttonType="secondary"
        leftIcon={<RemoveRedEyeOutlined />}
        text={qrCodeButtonText}
        format={"fill"}
        onClick={() => props.qrCodes.length > 0 && setIsQrCodeModalOpen(true)}
        disabled={props.qrCodes.length < 1}
      />
      <CardsQrCodesModal
        vehicle={props.vehicle}
        qrCodes={props.qrCodes}
        isQrCodeModalOpen={isQrCodeModalOpen}
        setIsQrCodeModalOpen={setIsQrCodeModalOpen}
      />
    </SidePanelDS.Section>
  );
};

type Informations = {
  name: string | null;
  lastName?: string;
  uuid: string | null;
  reference?: string | null;
  model?: string | null;
};

type VehicleInformation = Informations & {
  type: VehicleType;
};

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const StyledAssets = styled.div`
  display: flex;
  justify-content: center;
  & svg {
    width: 13rem;
    height: 9.5rem;
  }
`;

const StyledTitle = styled.div`
  ${typographies["Body/XL"]}
`;

const StyledSubTitle = styled.div`
  ${typographies["Body/L"]}
`;

const StyledSelect = styled.div`
  height: 2.5rem;
`;

const StyledBodyM = styled.div`
  ${typographies["Body/M"]}
`;

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledIconColor = styled.div<{ $isInOpposition: boolean }>`
  & svg {
    fill: ${({ $isInOpposition }) => ($isInOpposition ? "grey" : "")};
  }
`;

const StyledLittleIcon = styled.div`
  display: flex;
  cursor: pointer;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledUnits = styled.div`
  display: flex;
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledRow = styled.div`
  display: flex;
`;
