import { PageDS, Spacer, TabMenu, ToastContainer } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardTransactionsTab } from "./cardTransactionsTab";
import { BadgeTransactionsTab } from "./badgeTransactionsTab";
import { useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "../homeSlice";

export const Transactions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const company = useAppSelector(selectCompany);
  const [selectedTab, setSelectedTab] = useState<string>(params.tab ?? "cards");

  const tabs = useMemo(() => {
    const cardsTab = {
      name: "cards",
      title: t("transactions.tab.cards"),
    };
    if (company?.canOrderBadges) {
      return [
        cardsTab,
        {
          name: "badges",
          title: t("transactions.tab.badges"),
        },
      ];
    } else {
      return [cardsTab];
    }
  }, [company, t]);

  useEffect(() => {
    if (company && !company.canOrderBadges && selectedTab === "badges") {
      setSelectedTab("cards");
    }
  }, [company, selectedTab]);

  useEffect(() => {
    if (selectedTab) {
      navigate(
        `/home/transactions/${selectedTab}${
          params.id !== undefined ? `/${params.id}` : ""
        }`,
      );
    }
  }, [navigate, params.id, selectedTab]);

  return (
    <PageDS
      title={t("transactions.title")}
      subTitle={t("transactions.subTitle")}
      toaster={<ToastContainer />}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Spacer y={0.5} />
        </>
      }
    >
      <CardTransactionsTab />
      <BadgeTransactionsTab />
    </PageDS>
  );
};
