import { PageDS } from "@qivia/ui";
import {
  PublicCompany,
  PublicManager,
  selectLoadCompanyStatus,
} from "../../homeSlice";
import { Params, useNavigate } from "react-router-dom";
// import { PlatformSepaDebitModal } from "./platformSepaDebitModal";
import { ExternalBankAccountModal } from "./externalBankAccountModal";
import { useEffect, useMemo } from "react";
import {
  getCompanySepaDebitInfoByAccountProviderIdAsync,
  selectCompanyMemobankMandatePerCompany,
} from "../sepaDebitSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { MemobankMandateModal } from "./memobankMandateModal";

export const SepaDebitModals = (props: {
  company: PublicCompany;
  manager: PublicManager;
  params: Readonly<Params<string>>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const companyStatus = useAppSelector(selectLoadCompanyStatus);
  const companyMandate = useAppSelector(selectCompanyMemobankMandatePerCompany);
  const modalType = useMemo(
    () => ["external_account", "mandate_signature_request"],
    [],
  ); //TO ADD HERE TO DISPLAY SIGN PLATEFORM MANDATE

  if (
    !props.params.sepa_debit ||
    !modalType.includes(props.params.sepa_debit)
  ) {
    navigate(`/home/billings/account`);
  }

  useEffect(() => {
    if (
      !props.params.sepa_debit ||
      !modalType.includes(props.params.sepa_debit)
    ) {
      navigate(`/home/billings/account`);
    }
  }, [modalType, navigate, props.params.sepa_debit]);

  useEffect(() => {
    void dispatch(
      getCompanySepaDebitInfoByAccountProviderIdAsync({
        accountProviderId: props.company.providerId,
      }),
    );
  }, [dispatch, props.company.providerId]);

  useEffect(() => {
    if (
      props.params.sepa_debit &&
      ["external_account", "mandate_signature_request"].includes(
        props.params.sepa_debit,
      ) &&
      !props.company.externalIban
    ) {
      navigate(`/home/billings/account/external_account`);
    }
  }, [navigate, props.params.sepa_debit, props.company.externalIban]);

  useEffect(() => {
    if (companyStatus === "processing" || props.manager.role !== "OWNER") {
      navigate(`/home/billings/account`);
    }
  }, [companyStatus, dispatch, navigate, props.manager.role]);

  return (
    <PageDS
      title={t(`account.${props.params.sepa_debit}.modal.title`) || ""}
      titleElement={{
        closeButtonAction: () => navigate(`/home/billings/account`),
      }}
    >
      <ExternalBankAccountModal
        company={props.company}
        isVisible={
          props.params.sepa_debit === "external_account" &&
          !props.company.externalIban &&
          props.manager.role === "OWNER"
        }
      />
      {/* <PlatformSepaDebitModal
          company={props.company}
          manager={props.manager}
          isVisible={
            props.params.sepa_debit === "sign_platform_mandate" &&
            !props.company.platformSepaMandateSigned &&
            props.manager.role === "OWNER"
          }
        /> */}
      <MemobankMandateModal
        company={props.company}
        manager={props.manager}
        isVisible={
          props.params.sepa_debit === "mandate_signature_request" &&
          !props.company.memoBankMandateSigned &&
          !companyMandate &&
          props.manager.role === "OWNER"
        }
      />
    </PageDS>
  );
};
