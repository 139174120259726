import { isAfter, isBefore, isEqual } from "date-fns";
import { AdvancedAnalyticsVehicles } from "./fleetApi";
import {
  DriverPlanningByDay,
  PlanningDetailsType,
  Trips,
  TripsAndPlanningsHistory,
  TripsDetails,
} from "./tripsApi";

export const getTripDurationVariation = (
  trip: TripsDetails,
  tripsPlanning: PlanningDetailsType | null,
) => {
  if (!trip.secondsDuration || !tripsPlanning?.endTime) return null;

  return Number(
    Number(
      trip.secondsDuration -
        (new Date(tripsPlanning?.endTime).getTime() -
          new Date(tripsPlanning?.startTime).getTime()) /
          1000,
    ).toFixed(2),
  );
};

export const getTripDurationVariationPercentage = (
  trip: TripsDetails,
  tripsPlanning: PlanningDetailsType | null,
) => {
  if (!trip.secondsDuration || !tripsPlanning?.endTime) return null;
  const tripDurationVariation = getTripDurationVariation(trip, tripsPlanning);
  if (!tripDurationVariation) return null;

  return (
    tripDurationVariation /
    (new Date(tripsPlanning?.endTime).getTime() -
      new Date(tripsPlanning?.startTime).getTime()) /
    1000
  );
};

export const getTripDistanceVariation = (
  trip: TripsDetails,
  tripsPlanning: PlanningDetailsType | null,
) => {
  if (!trip.distance || !tripsPlanning?.expectedMileage) return null;

  return Number(
    Number(trip.distance - tripsPlanning.expectedMileage).toFixed(2),
  );
};

export const getTripDistanceVariationPercentage = (
  trip: TripsDetails,
  tripsPlanning: PlanningDetailsType | null,
) => {
  if (!trip.distance || !tripsPlanning?.expectedMileage) return null;
  const tripDistanceVariation = getTripDistanceVariation(trip, tripsPlanning);
  if (!tripDistanceVariation) return null;

  return tripDistanceVariation / tripsPlanning.expectedMileage;
};

export const getTotalDistanceVariation = (
  trips: Trips,
  plannings: DriverPlanningByDay,
) => {
  const tripDistance = trips.totalDistanceInKm;
  const planningDistance = plannings.expectedMileagePerDay;

  return tripDistance - planningDistance;
};

export const getTotalDistanceVariationPercentage = (
  trips: Trips,
  plannings: DriverPlanningByDay,
) => {
  const tripDistance = trips.totalDistanceInKm;
  const planningDistance = plannings.expectedMileagePerDay;

  const variation = Number(
    ((tripDistance - planningDistance) / planningDistance).toFixed(2),
  );

  return variation * 100;
};

export const getTotalDurationVariation = (
  trips: Trips,
  plannings: DriverPlanningByDay,
) => {
  const tripSecondsDuration = trips.hoursDuration * 3600;
  const planningSecondsDuration = plannings.expectedDurationPerDay;

  return Number(
    ((tripSecondsDuration - planningSecondsDuration) / 3600).toFixed(2),
  );
};

export const getTotalDurationVariationPercentage = (
  trips: Trips,
  plannings: DriverPlanningByDay,
) => {
  const tripSecondsDuration = trips.hoursDuration * 3600;
  const planningSecondsDuration = plannings.expectedDurationPerDay;

  const variation = Number(
    (
      (tripSecondsDuration - planningSecondsDuration) /
      planningSecondsDuration
    ).toFixed(2),
  );

  return variation * 100;
};

export const getTripsAndPlanningsHistory = (payload: {
  trips: Trips[];
  plannings: DriverPlanningByDay[];
}): TripsAndPlanningsHistory[] => {
  return payload.trips.map((t) => {
    const associatedPlanning = payload.plannings.find(
      (p) =>
        p.driverUuid === t.driver?.uuid &&
        p.date.slice(0, 10) === t.date.slice(0, 10),
    );
    return {
      ...t,
      trips: t.trips.map((tripDetails) => {
        const tripsPlanning =
          associatedPlanning?.trips.find(
            (apt) =>
              (isAfter(
                new Date(tripDetails.startDate),
                new Date(apt.startTime),
              ) ||
                isEqual(
                  new Date(tripDetails.startDate),
                  new Date(apt.startTime),
                )) &&
              (isBefore(new Date(tripDetails.endDate), new Date(apt.endTime)) ||
                isEqual(new Date(tripDetails.endDate), new Date(apt.endTime))),
          ) ?? null;
        return {
          ...tripDetails,
          distanceVariation: getTripDistanceVariation(
            tripDetails,
            tripsPlanning,
          ),
          distanceVariationPercent: getTripDistanceVariationPercentage(
            tripDetails,
            tripsPlanning,
          ),
          durationVariation: getTripDurationVariation(
            tripDetails,
            tripsPlanning,
          ),
          durationVariationPercent: getTripDurationVariationPercentage(
            tripDetails,
            tripsPlanning,
          ),
        };
      }),
      distanceVariation: associatedPlanning
        ? getTotalDistanceVariation(t, associatedPlanning)
        : null,
      distanceVariationPercent: associatedPlanning
        ? getTotalDistanceVariationPercentage(t, associatedPlanning)
        : null,
      driveTimeVariation: associatedPlanning
        ? getTotalDurationVariation(t, associatedPlanning)
        : null,
      driveTimeVariationPercent: associatedPlanning
        ? getTotalDurationVariationPercentage(t, associatedPlanning)
        : null,
    };
  });
};

export const getAdvancedAnalyticsVehiclesGroupByVehicle = (
  vehicles: AdvancedAnalyticsVehicles[],
) =>
  vehicles.reduce(
    (acc, v) => ({
      ...acc,
      [v.uuid]: v,
    }),
    {} as Record<string, AdvancedAnalyticsVehicles>,
  );
