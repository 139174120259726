import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { TextCapitalized } from "./text/TextCapitalized";
import { typographies } from "../../styles/figmaTypographies";

export interface DataCardProps {
  titleElement?: JSX.Element;
  title: string | JSX.Element;
  titleHeight?: string;
  content?: string | JSX.Element;
  backgroundColor?: string;
  borderRadius?: number;
  withoutBorder?: boolean;
  width?: number;
}

export const DataCard = (props: DataCardProps) => {
  return (
    <StyledContainer
      $backgroundColor={props.backgroundColor ?? null}
      $width={props.width ?? null}
      $borderRadius={props.borderRadius ?? null}
      $withoutBorder={props.withoutBorder ?? false}
    >
      <Spacer y={1.5} />
      <StyledFlex>
        <Spacer x={1.5} />
        <StyledColumn>
          {props.titleElement && (
            <>
              {props.titleElement}
              <Spacer y={1} />
            </>
          )}
          <StyledTitle $titleHeight={props.titleHeight ?? null}>
            <TextCapitalized>{props.title}</TextCapitalized>
          </StyledTitle>
          <Spacer y={1} />
          {props.content}
        </StyledColumn>
        <Spacer x={1.5} />
      </StyledFlex>
      <Spacer y={1.5} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{
  $backgroundColor: string | null;
  $width: number | null;
  $borderRadius: number | null;
  $withoutBorder: boolean;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${({ $backgroundColor }) => $backgroundColor ?? ""};
  border: ${({ $withoutBorder }) =>
    $withoutBorder
      ? ""
      : `0.0625rem solid ${colors["colors/borders/cells/cells"]}`};
  border-radius: ${({ $borderRadius }) =>
    $borderRadius ? `${$borderRadius}rem` : "0.75rem"};
  width: ${({ $width }) => ($width ? `${$width}rem` : "100%")};
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StyledTitle = styled.div<{ $titleHeight: string | null }>`
  ${({ $titleHeight }) => $titleHeight ?? typographies["Header/H2"]};
`;
