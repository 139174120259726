import styled from "styled-components";
import { CardChart } from "@qivia/ui/src/designSystem/components/Charts/CardChart";
import { useTranslation } from "react-i18next";
import { CategoryPieChart } from "./charts/categoryPieChart";
import { PricePerLiterLineChart } from "./charts/pricePerLiterLineChart";
import { CarbonEmissionLineChart } from "./charts/carbonEmissionLineChart";
import { MerchantGroupsPieChart } from "./charts/merchantGroupsPieChart";
import { PricePerLiterPerFuelMultipleLineChart } from "./charts/pricePerLiterPerFuelMultipleLineChart";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getTransactionsAmountsPerPeriodAsync,
  selectTransactionsAmountsPerPeriod,
} from "../transactions/transactionsSlice";
import { useEffect } from "react";
import {
  getCompanyBalancePerPeriodAsync,
  selectBalancePerPeriod,
  selectBalancePerPeriodStatus,
  selectCompany,
} from "../homeSlice";
import { BalanceChart } from "./charts/balanceChart";

export const DashboardCharts = () => {
  const { t } = useTranslation();

  const transactionsAmountsPerPeriod = useAppSelector(
    selectTransactionsAmountsPerPeriod,
  );

  const balancePerPeriod = useAppSelector(selectBalancePerPeriod);
  const balancePerPeriodStatus = useAppSelector(selectBalancePerPeriodStatus);

  const company = useAppSelector(selectCompany);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (company) {
      void dispatch(
        getTransactionsAmountsPerPeriodAsync({
          companyUuid: company.uuid,
          date: new Date().toISOString(),
        }),
      );
      if (balancePerPeriodStatus === "idle")
        void dispatch(
          getCompanyBalancePerPeriodAsync({
            companyUuid: company.uuid,
            date: new Date().toISOString(),
          }),
        );
    }
  }, [dispatch, company, balancePerPeriodStatus]);

  return (
    <StyledContainer>
      {transactionsAmountsPerPeriod && (
        <StyledChartCards>
          <CardChart
            title={t("dashboard.charts.title.category")}
            isLineChart={false}
            isVisible={
              transactionsAmountsPerPeriod.year.categoriesAmounts.length > 0
            }
          >
            {(totalType) => (
              <CategoryPieChart
                totalType={totalType}
                data={transactionsAmountsPerPeriod}
              />
            )}
          </CardChart>
          <CardChart
            title={t("dashboard.charts.title.pricePerLiter")}
            isLineChart={true}
            isVisible={
              transactionsAmountsPerPeriod.year.averagePricePerLiter.length > 0
            }
          >
            {(totalType) => (
              <PricePerLiterLineChart
                totalType={totalType}
                data={transactionsAmountsPerPeriod}
              />
            )}
          </CardChart>
          <CardChart
            title={t("dashboard.charts.title.carbonEmission")}
            isLineChart={true}
            isVisible={
              transactionsAmountsPerPeriod.year.totalCarbonEmission.length > 0
            }
          >
            {(totalType) => (
              <CarbonEmissionLineChart
                totalType={totalType}
                data={transactionsAmountsPerPeriod}
              />
            )}
          </CardChart>
          <CardChart
            title={t("dashboard.charts.title.merchantGroups")}
            isLineChart={false}
            isVisible={
              transactionsAmountsPerPeriod.year.merchantGroupsAmounts.length > 0
            }
          >
            {(totalType) => (
              <MerchantGroupsPieChart
                totalType={totalType}
                data={transactionsAmountsPerPeriod}
              />
            )}
          </CardChart>
          <CardChart
            title={t("dashboard.charts.title.pricePerLiterPerFuel")}
            isLineChart={true}
            isVisible={
              transactionsAmountsPerPeriod.year.averagePricePerLiterPerFuel
                .length > 0
            }
          >
            {(totalType) => (
              <PricePerLiterPerFuelMultipleLineChart
                totalType={totalType}
                data={transactionsAmountsPerPeriod}
              />
            )}
          </CardChart>
          <CardChart
            title={t("dashboard.charts.title.balancePerPeriod")}
            isLineChart={true}
            isVisible={
              balancePerPeriodStatus === "success" &&
              !!balancePerPeriod &&
              (balancePerPeriod.month.length > 1 ||
                balancePerPeriod.year.length > 1 ||
                balancePerPeriod.quarter.length > 1)
            }
          >
            {(totalType) => <BalanceChart totalType={totalType} />}
          </CardChart>
        </StyledChartCards>
      )}
    </StyledContainer>
  );
};

const StyledChartCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const StyledContainer = styled.div`
  display: flex;
  max-width: 70rem;
  overflow-x: auto;
`;
